const MAX_WIDTH = 1280;
const MAX_HEIGHT = 720;

const handleFiles = (e: any, callback: any): void => {
  const img = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  img.onload = function () {
    const iw = img.width;
    const ih = img.height;
    const scale = Math.min(MAX_WIDTH / iw, MAX_HEIGHT / ih);
    const iwScaled = iw * scale;
    const ihScaled = ih * scale;
    canvas.width = iwScaled;
    canvas.height = ihScaled;
    if (ctx != null) {
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
    }
    callback(canvas.toDataURL('image/jpeg', 0.8));
  };
  if (e.target != null && e.target.files != null) {
    img.src = URL.createObjectURL(e.target.files[0]);
  }
};

export default handleFiles;
