import React from 'react';
import { Divisor } from './HomeDivisor.elements';

type HomeDivisorProps = {
  width?: number;
};

const HomeDivisor: React.FC<HomeDivisorProps> = ({
  width,
}: HomeDivisorProps) => {
  return (
    <>
      <Divisor width={width} />
    </>
  );
};

export default HomeDivisor;
