import React, { useState, useEffect, useCallback } from 'react';
import { Search } from '../../../../assets/icons';
import { Doctor } from '../../../../components/DoctorsList';
import { Divisor } from '../../../../components/HomeComponents/HomeDivisor/HomeDivisor.elements';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';

import {
  AddParticipantModalContent,
  AddParticipantTitle,
  AddParticipantsCurrentContainer,
  AddParticipantSearchContainer,
  AddParticipantButtonsContainer,
  AddParticipantButton,
  SearchParticipantContainer,
  AddParticipantsList,
  IconContainer,
  SearchParticipant,
  AddParticipantsCurrentContainerTitle,
  AddParticipantsCurrentItem,
  AddParticipantsCurrentList,
  AddParticipantsCurrentText,
  DoctorContainer,
  OrderDoctors,
} from './AddParticipant.elements';

import { useFilter } from '../../../CasesDiscussion/Filter/FilterContext';

interface IAddParticipantProps {
  modalAction: () => void;
  currentParticipants: any[];
  setParticipants: any;
  newParticipants: any[];
  setNewParticipants: any;
  removedParticipants: any[];
  setRemovedParticipants: any;
  setParticipantsPhotos?: any;
}

export const AddParticipant: React.FC<IAddParticipantProps> = ({
  modalAction,
  currentParticipants,
  setParticipants,
  newParticipants,
  setNewParticipants,
  removedParticipants,
  setRemovedParticipants,
  setParticipantsPhotos,
}: IAddParticipantProps) => {
  const { user } = useAuth();
  const [listParticipants, setListParticipants] = useState<any>([]);
  const [primaryListParticipants, setPrimaryListsParticipants] = useState<any>(
    [],
  );
  const [alreadyParticipants, setAlreadyParticipants] = useState<any>(() => {
    return currentParticipants ? currentParticipants : [];
  });
  const [currentNewParticipants, setCurrentNewParticipants] = useState<any>(
    () => {
      return newParticipants ? newParticipants : [];
    },
  );
  const [deletedParticipants, setDeletedParticipants] =
    useState<any>(removedParticipants);
  const { changeDoctorsList } = useFilter();
  const [search, setSearch] = useState('');
  const [speciality, setSpeciality] = useState<string>('true');

  const loadParticipants = useCallback(
    async (isSonhe = '') => {
      console.log('ENTROU');
      const res = await api.get(
        `users/?text=${search}&admin=false&withoutMe=${user.id}&isSonhe=${isSonhe}&speciality=${speciality}`,
      );
      console.log(res.data);
      const listPart = [] as any[];

      res.data?.forEach((user: any) => {
        let exists = false;
        alreadyParticipants?.forEach((part: any) => {
          if (user.id === part.id) {
            exists = true;
          }
        });
        if (!exists) {
          listPart.push(user);
        }
      });

      setListParticipants(listPart);
      setPrimaryListsParticipants(listParticipants);
    },
    [alreadyParticipants, search, user.id],
  );

  const selectParticipants = (doctor: { id: string }) => {
    let result = false;
    for (const nw of alreadyParticipants) {
      if (nw.id === doctor.id) {
        result = true;
      }
    }

    if (!result) {
      let nwP = [] as any[];
      const alrP = [...alreadyParticipants, doctor];
      setAlreadyParticipants(alrP);
      if (currentNewParticipants?.length) {
        nwP = [...currentNewParticipants, doctor];
        setCurrentNewParticipants(nwP);
      } else {
        nwP.push(doctor);
        setCurrentNewParticipants(nwP);
      }
    }
  };

  const filterWithSpecialities = (specialtyId: string) => {
    if (specialtyId == 'noSpecialty') {
      setListParticipants(primaryListParticipants);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-array-constructor
      const newData = Array();
      const data = primaryListParticipants.map(
        (user: { users_specialties: any }) => {
          user.users_specialties.forEach(
            (specialty: { speciality_id: string }) => {
              // console.log(specialty.speciality_id);
              if (specialty.speciality_id == specialtyId) {
                // console.log(`COMPARAR: ${specialty.speciality_id} X ${specialtyId}`);
                newData.push(user);
              }
            },
          );
        },
      );
      if (newData) {
        setListParticipants(newData);
      }
    }
  };

  const removeParticipants = (id: string) => {
    if (id != user.id) {
      const filteredParticipants = alreadyParticipants.filter(
        (pt: { id: string }) => {
          return pt.id != id;
        },
      );
      const rmP = [...deletedParticipants, id];
      setDeletedParticipants(rmP);
      setAlreadyParticipants(filteredParticipants);
      if (currentNewParticipants?.length) {
        const filteredNewParticipants = currentNewParticipants.filter(
          (pt: { id: string }) => {
            return pt.id != id;
          },
        );
        setCurrentNewParticipants(filteredNewParticipants);
      }
    }
  };

  const confirmChanges = () => {
    const part = [...alreadyParticipants];
    const nwp = [...currentNewParticipants];
    const rmPart = [...deletedParticipants];
    setRemovedParticipants(rmPart);
    setParticipants(part);
    setNewParticipants(nwp);
    const aux = part;
    if (aux.length > 6) {
      setParticipantsPhotos(aux.slice(0, 6));
    } else {
      setParticipantsPhotos(aux);
    }
  };

  useEffect(() => {
    loadParticipants();
  }, [search, alreadyParticipants, loadParticipants]);

  return (
    <AddParticipantModalContent>
      <AddParticipantTitle>
        Busque um médico ou encaminhador para ser adicionado
      </AddParticipantTitle>
      <AddParticipantSearchContainer>
        <SearchParticipantContainer>
          <IconContainer>
            <Search />
          </IconContainer>
          <SearchParticipant
            placeholder="Busque pelo nome"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <OrderDoctors
            onChange={e => {
              if (e.target.value == 'isSonhe') {
                loadParticipants(true);
              } else if (e.target.value == 'notSonhe') {
                loadParticipants(false);
              } else {
                loadParticipants();
              }
            }}
          >
            <option value="" selected>
              Filtrar médicos
            </option>
            <option value="isSonhe">Médicos SOnHe</option>
            <option value="notSonhe">Encaminhadores</option>
          </OrderDoctors>
          <OrderDoctors onChange={e => filterWithSpecialities(e.target.value)}>
            <option value="noSpecialty" selected>
              Filtrar especialidades
            </option>
            <option value="fa2641de-2b33-427e-ba72-6113feaea104">
              Oncologista
            </option>
            <option value="e53faa2c-9455-4c5a-b88f-bc566b927ffa">
              Tumores de Pele
            </option>
            <option value="11a3b53e-e9d7-44db-9513-0433bce789fc">
              Cabeça e pescoço
            </option>
            <option value="bdb015cb-474a-4709-82a2-7dd9cf47feff">
              Pneumologista
            </option>
            <option value="eb3b106d-d0d4-4e42-bae5-e18e3bba0a0d">
              Tumores femininos
            </option>
            <option value="eaaaed56-859e-47a1-98e1-ba6e9983d625">
              Pediatra
            </option>
            <option value="36700468-bd4b-48fd-99ad-facc43ba350d">
              Tumores gastrointestinais
            </option>
            <option value="5694eeeb-b7e3-41b4-8552-74b72beb80da">
              Oncologia Torácica
            </option>
            <option value="c08694f8-e4be-41d9-b239-77323d528c0a">Outros</option>
          </OrderDoctors>
        </SearchParticipantContainer>
      </AddParticipantSearchContainer>
      <AddParticipantsCurrentContainer>
        <AddParticipantsCurrentContainerTitle>
          Já selecionados:
        </AddParticipantsCurrentContainerTitle>
        <AddParticipantsCurrentList>
          {alreadyParticipants?.map(
            (pt: { id: string; name: string; surname: string }) => (
              <AddParticipantsCurrentItem key={pt.id}>
                {pt.id !== user.id ? (
                  <span onClick={() => removeParticipants(pt.id)}>Remover</span>
                ) : (
                  <span style={{ color: '#1F3249' }}>Owner</span>
                )}
                <AddParticipantsCurrentText>{`${pt.name} ${pt.surname}`}</AddParticipantsCurrentText>
              </AddParticipantsCurrentItem>
            ),
          )}
        </AddParticipantsCurrentList>
      </AddParticipantsCurrentContainer>
      <AddParticipantsList>
        <Divisor width={100} />
        {listParticipants?.map(
          (doctor: {
            id: string;
            photo_address: string;
            name: string;
            surname: string;
            crm: string;
            //users_specialties: any;
            email: string;
          }) => (
            <DoctorContainer
              key={doctor.id}
              onClick={() => selectParticipants(doctor)}
            >
              <Doctor
                key={doctor.id}
                userId={doctor.id}
                doctorImage={doctor.photo_address}
                doctorName={doctor.name + ' ' + doctor.surname}
                CRM={doctor.crm}
                //doctorSpecialty={doctor.users_specialties}
                doctorEmail={doctor.email}
              />
              <Divisor width={100} />
            </DoctorContainer>
          ),
        )}
      </AddParticipantsList>
      <AddParticipantButtonsContainer>
        <AddParticipantButton cancel={true} onClick={modalAction}>
          Fechar
        </AddParticipantButton>
        <AddParticipantButton
          onClick={() => {
            confirmChanges();
            modalAction();
          }}
        >
          Confirmar
        </AddParticipantButton>
      </AddParticipantButtonsContainer>
    </AddParticipantModalContent>
  );
};

export default AddParticipant;
