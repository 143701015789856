import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import './multiselect_styles.css';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import {
  Edit,
  InserDrive,
  Feedback,
  FormatSize,
  Delete,
  Exit,
} from '../../assets/icons';
import { Modal, HomeDivisor } from '../../components';

import {
  FeedbackForDevelopers,
  PrivacyTerms,
  SignOut,
  DeleteAccount,
  EditPhoto,
  ChangeFontSize,
} from './ModalContents';

import {
  GridContainer,
  PageTitle,
  ProfileContainer,
  ProfileImageContainer,
  ProfileImage,
  ProfileImageEditButton,
  FieldsListContainer,
  OptionsContainer,
  Input,
  Label,
  FieldContainer,
  TextArea,
  OptionContainer,
  OptionIconContainer,
  OptionText,
  SaveChangesButton,
  NameAndSurnameContainer,
  FieldContainerNameAndSurname,
} from './Profile.elements';
import { LoaderContainer } from '../Home/Home.elements';
import Loader, { ThreeDots } from 'react-loader-spinner';
import theme from '../../assets/styles/theme';

interface IUserProps {
  name: string;
  surname: string;
  expertise: string;
  description: string;
  avatar_url: string;
}

const Profile: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalRef, setModalRef] = useState<string>('');
  const { ...authInfo } = useAuth();
  const [user, setUser] = useState<IUserProps>(() => {
    return {} as IUserProps;
  });
  const [services, setServices] = useState([]); // all services registered in SOnHe plataform
  const [oldServices, setOldServices] = useState<any>([]); // services related to the user before change
  const [selectedServices, setSelectedServices] = useState([]);
  const [newAvatar, setNewAvatar] = useState<any>('');
  const [specialties, setSpecialties] = useState([]); // all specialties registered in SOnHe plataform
  const [oldSpecialties, setOldSpecialties] = useState<any>([]); // specialties related to the user before change
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);

  const loadUser = useCallback(async () => {
    setLoading(true);
    const res = await api.get(`/users/${authInfo.user.id}`);
    if (res && res.data) {
      setUser({
        name: res.data.name,
        surname: res.data.surname,
        expertise: res.data.speciality,
        avatar_url: res.data.photo_address,
        description: res.data.bio,
      });
    }
    setLoading(false);
  }, [authInfo.user.id]);

  const loadSpecialties = async () => {
    const res = await api.get('/specialties');
    if (res && res.data) {
      const data = res.data.map((specialty: { id: string; name: string }) => ({
        value: specialty.id,
        label: specialty.name,
      }));
      setSpecialties(data);
    }
  };

  const loadServices = async () => {
    const res = await api.get('/services');
    if (res && res.data) {
      const data = res.data.map((services: { id: string; name: string }) => ({
        value: services.id,
        label: services.name,
      }));
      setServices(data);
    }
  };

  const loadSelectedSpecialties = async () => {
    const res = await api.get(
      `/specialties-users-doctor-id/${authInfo.user.id}`,
    );
    if (res && res.data) {
      const data = res.data.map(
        (specialtiesUsers: {
          id: string;
          speciality: { id: string; name: string };
        }) => ({
          id: specialtiesUsers.id,
          value: specialtiesUsers.speciality.id,
          label: specialtiesUsers.speciality.name,
        }),
      );
      setSelectedSpecialties(data);
      setOldSpecialties(data);
    }
  };

  const loadSelectedServices = async () => {
    const res = await api.get(`/services-users?userId=${authInfo.user.id}`);
    if (res && res.data) {
      const data = res.data.map(
        (servicesUsers: {
          id: string;
          service: { id: string; name: string };
        }) => ({
          id: servicesUsers.id,
          value: servicesUsers.service.id,
          label: servicesUsers.service.name,
        }),
      );
      setSelectedServices(data);
      setOldServices(data);
    }
  };

  const handleDeleteServicesUsers = async () => {
    oldServices.forEach(
      (oldServiceUser: { id: string; value: string; label: string }) => {
        let flag = false;
        selectedServices.forEach(
          (newServiceUser: { id: string; value: string; label: string }) => {
            if (oldServiceUser.value == newServiceUser.value) {
              flag = true;
            }
          },
        );
        if (flag == false) {
          deleteServiceUser(oldServiceUser.id);
        }
      },
    );
  };

  const handleDeleteSpecialtiesUsers = async () => {
    oldSpecialties.forEach(
      (oldSpecialtyUser: { id: string; value: string; label: string }) => {
        let flag = false;
        selectedSpecialties.forEach(
          (newSpecialtyUser: { id: string; value: string; label: string }) => {
            if (oldSpecialtyUser.value == newSpecialtyUser.value) {
              flag = true;
            }
          },
        );
        if (flag == false) {
          deleteSpecialtyUser(oldSpecialtyUser.id);
        }
      },
    );
  };

  const handleCreateServicesUsers = async () => {
    selectedServices.forEach(
      (newServiceUser: { value: string; label: string }) => {
        let flagTwo = false;
        oldServices.forEach(
          (oldServiceUser: { value: string; label: string }) => {
            if (newServiceUser.value == oldServiceUser.value) {
              flagTwo = true;
            }
          },
        );
        if (flagTwo == false) {
          createServiceUser(newServiceUser.value, authInfo.user.id);
        }
      },
    );
  };

  const handleCreateSpecialtiesUsers = async () => {
    selectedSpecialties.forEach(
      (newSpecialtyUser: { value: string; label: string }) => {
        let flagTwo = false;
        oldSpecialties.forEach(
          (oldSpecialtyUser: { value: string; label: string }) => {
            if (newSpecialtyUser.value == oldSpecialtyUser.value) {
              flagTwo = true;
            }
          },
        );
        if (flagTwo == false) {
          createSpecialtyUser(newSpecialtyUser.value, authInfo.user.id);
        }
      },
    );
  };

  const handleServices = async () => {
    await handleDeleteServicesUsers();
    await handleCreateServicesUsers();
    setOldServices(selectedServices);
  };

  const handleSpecialties = async () => {
    await handleDeleteSpecialtiesUsers();
    await handleCreateSpecialtiesUsers();
    setOldSpecialties(selectedSpecialties);
  };

  const deleteServiceUser = async (serviceUserId: string) => {
    const res = await api.delete(`/services-users/${serviceUserId}`);
  };

  const deleteSpecialtyUser = async (specialtyUserId: string) => {
    const res = await api.delete(`/specialties-users/${specialtyUserId}`);
  };

  const createServiceUser = async (serviceId: string, userId: string) => {
    const res = await api.post('services-users', {
      service_id: serviceId,
      user_id: userId,
    });
  };

  const createSpecialtyUser = async (specialtyId: string, userId: string) => {
    const res = await api.post('specialties-users', {
      speciality_id: specialtyId,
      user_id: userId,
    });
  };

  const updateUser = async () => {
    setLoading(true);
    if (newAvatar != '' && newAvatar != null) {
      await updateUserAvatar();
    }

    const res = await api.put(`/users/${authInfo.user.id}`, {
      name: user.name,
      surname: user.surname,
      speciality: user.expertise,
      bio: user.description,
    });

    authInfo.updateUser({
      id: res.data.id,
      name: res.data.name,
      surname: res.data.surname,
      photo_address: res.data.photo_address,
      bio: res.data.bio,
      speciality: res.data.speciality,
      isSonhe: res.data.isSonhe,
    });

    await loadUser();
  };

  const updateUserAvatar = async () => {
    const data = new FormData();
    data.append('files', newAvatar);

    await api.post(`/files/users/${authInfo.user.id}`, data);
  };

  useEffect(() => {
    loadUser();
    loadServices();
    loadSpecialties();
  }, [loadUser]);

  useEffect(() => {
    loadSelectedServices();
    loadSelectedSpecialties();
  }, []);

  const handleChangeState = (
    targetName: string,
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setUser({ ...user, [targetName]: event.target.value });
  };

  const handleProfilePhoto = useCallback(
    (previewAvatar: string, avatar: File) => {
      setUser(oldUser => ({ ...oldUser, avatar_url: previewAvatar }));
      setNewAvatar(avatar);
    },
    [],
  );
  const openOrCloseModal = () => {
    setShowModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  const openReferedModal = (e: React.MouseEvent) => {
    if (e.currentTarget.id) {
      setModalRef(e.currentTarget.id);
      openOrCloseModal();
    }
  };

  const saveChanges = async () => {
    await updateUser();
    await handleServices();
    await handleSpecialties();
  };

  const renderModalContent = () => {
    switch (modalRef) {
      case 'privacy':
        return <PrivacyTerms modalAction={openOrCloseModal} />;
      case 'feedback':
        return <FeedbackForDevelopers modalAction={openOrCloseModal} />;
      case 'signout':
        return <SignOut modalAction={openOrCloseModal} />;
      case 'deleteAccount':
        return <DeleteAccount modalAction={openOrCloseModal} />;
      case 'editPhoto':
        return (
          <EditPhoto
            modalAction={openOrCloseModal}
            changePhoto={handleProfilePhoto}
          />
        );
      case 'fontSize':
        return <ChangeFontSize modalAction={openOrCloseModal} />;
      default:
        return <div></div>;
    }
  };

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <ThreeDots color={theme.color.mainBrown} height={100} width={100} />
        </LoaderContainer>
      ) : (
        <>
          <Modal showModal={showModal} setShowModal={setShowModal}>
            {renderModalContent()}
          </Modal>
          <GridContainer>
            <PageTitle>Configurações</PageTitle>
            <ProfileContainer>
              <ProfileImageContainer>
                <ProfileImage src={user.avatar_url || theme.defaultImage} />
                <ProfileImageEditButton>
                  <Edit id="editPhoto" onClick={openReferedModal} />
                </ProfileImageEditButton>
              </ProfileImageContainer>
              <FieldsListContainer>
                <NameAndSurnameContainer>
                  <FieldContainerNameAndSurname>
                    <Label htmlFor="name">Nome</Label>
                    <Input
                      id="name"
                      placeholder="Seu nome"
                      type="text"
                      value={user.name}
                      onChange={event => handleChangeState('name', event)}
                    />
                  </FieldContainerNameAndSurname>
                  <FieldContainerNameAndSurname>
                    <Label htmlFor="surname">Sobrenome</Label>
                    <Input
                      id="surname"
                      placeholder="Seu sobrenome"
                      type="text"
                      value={user.surname}
                      onChange={event => handleChangeState('surname', event)}
                    />
                  </FieldContainerNameAndSurname>
                </NameAndSurnameContainer>
                <FieldContainer>
                  <Label htmlFor="especialidades">Especialidades</Label>
                  <MultiSelect
                    className="multiselects"
                    options={specialties}
                    value={selectedSpecialties}
                    onChange={setSelectedSpecialties}
                    labelledBy="especialidades"
                    overrideStrings={{
                      allItemsAreSelected:
                        'Todas as especialidades estão selecionadas.',
                      clearSearch: 'Limpar pesquisa',
                      noOptions: 'Sem opções',
                      search: 'Pesquise',
                      selectAll: 'Selecionar todas',
                      selectSomeItems: 'Selecione...',
                    }}
                  />
                </FieldContainer>
                <FieldContainer>
                  <Label htmlFor="servicos">Serviços</Label>
                  <MultiSelect
                    className="multiselects"
                    options={services}
                    value={selectedServices}
                    onChange={setSelectedServices}
                    labelledBy="servicos"
                    disabled={false}
                    overrideStrings={{
                      allItemsAreSelected:
                        'Todos os serviços estão selecionados.',
                      clearSearch: 'Limpar pesquisa',
                      noOptions: 'Sem opções',
                      search: 'Pesquise',
                      selectAll: 'Selecionar todos',
                      selectSomeItems: 'Selecione...',
                    }}
                  />
                </FieldContainer>
                <FieldContainer>
                  <Label htmlFor="description">Descrição</Label>
                  <TextArea
                    id="description"
                    placeholder="Descrição sobre você"
                    value={user.description}
                    onChange={event => handleChangeState('description', event)}
                  />
                </FieldContainer>
              </FieldsListContainer>
              <OptionsContainer>
                <OptionContainer>
                  <OptionIconContainer>
                    <InserDrive />
                  </OptionIconContainer>
                  <OptionText id="privacy" onClick={openReferedModal}>
                    Termos de privacidade
                  </OptionText>
                </OptionContainer>
                <OptionContainer>
                  <OptionIconContainer>
                    <Feedback />
                  </OptionIconContainer>
                  <OptionText id="feedback" onClick={openReferedModal}>
                    Feedback para os desenvolvedores
                  </OptionText>
                </OptionContainer>
                <OptionContainer>
                  <OptionIconContainer>
                    <FormatSize />
                  </OptionIconContainer>
                  <OptionText id="fontSize" onClick={openReferedModal}>
                    Tamanho da fonte
                  </OptionText>
                </OptionContainer>
                <HomeDivisor width={100} />
                <OptionContainer differentAction={true}>
                  <OptionIconContainer>
                    <Exit />
                  </OptionIconContainer>
                  <OptionText id="signout" onClick={openReferedModal}>
                    Sair da conta
                  </OptionText>
                </OptionContainer>
                <OptionContainer differentAction={true}>
                  <OptionIconContainer>
                    <Delete />
                  </OptionIconContainer>
                  <OptionText id="deleteAccount" onClick={openReferedModal}>
                    Apagar minha conta
                  </OptionText>
                </OptionContainer>
                <HomeDivisor width={100} />
                <SaveChangesButton onClick={saveChanges}>
                  Salvar alterações
                </SaveChangesButton>
              </OptionsContainer>
            </ProfileContainer>
          </GridContainer>
        </>
      )}
    </>
  );
};

export default Profile;
