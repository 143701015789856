import React, { memo, useEffect, useState } from 'react';
import { HomeCase } from '../../../components/HomeComponents';
import { countDeadline } from '../../../utils/countDays';
import { IFilterProps, useFilter } from '../Filter/FilterContext';
import { ThreeDots } from 'react-loader-spinner';
import theme from '../../../assets/styles/theme';
import {
  LoaderContainer,
  NoResultsMessage,
} from './ListCasesDiscussion.elements';

type Case = {
  title: string;
  deadline: string;
  status: string;
  id: number;
  photo: string;
};

interface ListCasesDiscussionProps {
  cases: Case[];
  loadCases: (filters: IFilterProps) => void;
  loadingDiscussions: boolean;
}

const ListCasesDiscussion: React.FC<ListCasesDiscussionProps> = ({
  cases,
  loadCases,
  loadingDiscussions,
}: ListCasesDiscussionProps) => {
  const { filters } = useFilter();

  useEffect(() => {
    loadCases(filters);
  }, [filters, loadCases]);

  return loadingDiscussions ? (
    <>
      {cases.length > 0 ? (
        cases?.map(({ title, deadline, status, id, photo }, index) => (
          <HomeCase
            key={index}
            backgroundColor="none"
            divisorWidth={100}
            deadline={
              countDeadline(deadline) > 0
                ? `${countDeadline(deadline)} dia(s)`
                : countDeadline(deadline) < 0
                ? 'Encerrado'
                : 'Hoje'
            }
            status={status}
            title={title}
            photo={photo}
            redirectTo={`/discussao/${id}`}
          />
        ))
      ) : (
        <NoResultsMessage>Sua busca não obteve resultados</NoResultsMessage>
      )}
    </>
  ) : (
    <LoaderContainer>
      <ThreeDots color={theme.color.mainBrown} height={100} width={100} />
    </LoaderContainer>
  );
};

export default memo(ListCasesDiscussion);
