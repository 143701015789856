import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface ButtonProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLButtonElement>> {
  cancel?: boolean;
}

export const ModalContent = styled.div`
  position: relative;
  z-index: 10;
  min-width: 40rem;
  min-height: 10rem;
  padding: 2.4rem;
  background: #fafafa;
  border: 0.5px solid #c9c9c9;
  border-radius: 0.5rem;
`;

export const ModalTitle = styled.h1`
  font: ${props => props.theme.font.modalTitle};
  color: ${props => props.theme.color.blue};
`;

export const ModalButton = styled.button<ButtonProps>`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;

  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props =>
    props.cancel ? props.theme.color.white : props.theme.color.mainBrown};
  color: ${props =>
    props.cancel ? props.theme.color.mainBrown : props.theme.color.white};
  border: 0.1rem solid ${props => props.theme.color.mainBrown} !important;

  &:hover {
    transition: 0.2s;
    background-color: ${props =>
      props.cancel
        ? props.theme.color.mainBrown
        : props.theme.color.hoverBrown};
    color: ${props => (props.cancel ? props.theme.color.white : null)};
  }
`;
