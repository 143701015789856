export default {
  defaultImage:
    'https://sonhe-s3.s3.amazonaws.com/c4739ff43136623663ae18b938b9c3f5-2f7f4c16-2ccf-4fbf-bfc9-28c1da43148d.png',
  color: {
    red: '#EB5757',
    green: '#27AE60',
    black: '#131313',
    blue: '#1F3249',
    mainBrown: '#846550',
    secondaryBrown: '#A78B6B',
    hoverBrown: '#9D826F',
    gray: '#D1D3D4',
    secondaryGray: '#E6E6F3',
    white: '#fff',
    backgroundGray: '#E5E5E5',
    whiteHover: '#F0F0F0',
    inputBorder: '#C9C9C9',
  },

  font: {
    bigText: `normal normal bold ${3.2}rem "Roboto", sans-serif`, // Roboto bold 32px: For titles
    bigTitle: `normal normal bold ${2.4}rem "Roboto", sans-serif`, // Roboto bold 24px: For titles
    mediumText: `normal normal bold ${1.8}rem "Roboto", sans-serif`, // Roboto bold 18px: For subtitles and other featured texts
    middleText: `normal normal ${1.8}rem "Roboto", sans-serif`,
    modalTitle: `normal normal bold ${2.4}rem "Roboto", sans-serif`, // Roboto bold 24px: For modal contents title
    normalText: `normal normal normal ${1.6}rem "Roboto", sans-serif`, // Roboto regular 16px: For navbar, placeholder's and some components description
    featuredNormalText: `normal normal bold ${1.6}rem "Roboto", sans-serif`, // Roboto regular 16px: For secondary featured texts, like doctor name on "Meus pacientes" component
    text: `normal normal normal ${1.4}rem "Roboto", sans-serif`, // Roboto regular 14px: For body texts
    featuredText: `normal normal bold ${1.4}rem "Roboto", sans-serif`, // Roboto regular 14px: For featured body texts
    smallText: `normal normal normal ${1.2}rem "Roboto", sans-serif`, // Roboto regular 12px: For main cards descriptions Ex: Dar feedback ao encaminhador,
  },
};
