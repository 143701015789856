import styled from 'styled-components';

export const OptionContainer = styled.div`
  padding: 1.7rem;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }
`;

export const Icon = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: blue;
`;
export const OptionsTitle = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.blue};
  margin: 0;
  margin-left: 2rem;
`;
