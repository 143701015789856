import React from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Title,
  SubTitle,
  ReplyContainer,
  QaContainer,
  TimelineContainer,
} from './HomeCard.elements';
import { Reply, QuestionAnswer, Timeline } from '../../../assets/icons';

type HomeCardProps = {
  title: string;
  subtitle: string;
  redirectTo: string;
  card1?: boolean;
  card2?: boolean;
  card3?: boolean;
};

const HomeCard: React.FC<HomeCardProps> = ({
  title,
  subtitle,
  redirectTo,
  card1,
  card2,
  card3,
}) => {
  return (
    <>
      <Container as={Link} to={redirectTo}>
        {card1 && (
          <ReplyContainer>
            <Reply />
          </ReplyContainer>
        )}
        {card2 && (
          <QaContainer>
            <QuestionAnswer />
          </QaContainer>
        )}
        {card3 && (
          <TimelineContainer>
            <Timeline />
          </TimelineContainer>
        )}
        <Title> {title} </Title>
        <SubTitle> {subtitle} </SubTitle>
      </Container>
    </>
  );
};

export default HomeCard;
