import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FilterProvider, IFilterProps } from './Filter/FilterContext';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import {
  GridContainer,
  DiscussionsContainer,
  DiscussionsContainerTitle,
  DiscussionsContainerHeader,
  DiscussionsContainerButton,
  DiscussionsContainerHeaderContainer,
  Divisor,
  DiscussionsList,
  FilterSection,
} from './CasesDiscussion.elements';
import Filter from './Filter/Filter';
import ListCasesDiscussion from './ListCasesDiscussion/ListCasesDiscussion';

const CasesDiscussion: React.FC = () => {
  const [casesDiscussion, setCasesDiscussion] = useState<any>([]);
  const [publicDiscussions, setPublicDiscussions] = useState<any>();
  const [LoadingDiscussions, setLoadingDiscussions] = useState(false);
  const { ...authInfo } = useAuth();

  const loadDiscussions = useCallback(
    async (filters: IFilterProps) => {
      setLoadingDiscussions(false);
      const res = await api.get(
        `/discussions-users-doctor-id/${authInfo.user.id}?text=${filters?.searchText}&discussion_status_id=${filters?.status}&dateOrder=${filters.deadlineOrder}`,
      );

      const pubDiscussions = await api.get(
        `/discussions/?simplifiedResult=true&text=${filters?.searchText}&dateOrder=${filters.deadlineOrder}&discussion_status_id=${filters?.status}&isPublic=true`,
      );
      if (res && res.data) {
        const data = [] as any[];
        res.data.forEach(
          (resData: {
            status: { emoji: string; name: string };
            deadline: string;
            title: string;
            isPublic: boolean;
            id: string;
            discussion_users: any;
          }) => {
            if (!resData.isPublic || resData.isPublic == null) {
              let owner: any;

              resData.discussion_users?.forEach((discussionUser: any) => {
                if (discussionUser?.isOwner) {
                  owner = discussionUser?.user;
                }
              });
              data.push({
                status: `${resData?.status?.emoji} ${resData?.status?.name}`,
                deadline: resData.deadline,
                title: resData.title,
                id: resData.id,
                photo: owner?.photo_address,
              });
            }
          },
        );
        setCasesDiscussion(data);
      }
      if (pubDiscussions && pubDiscussions.data) {
        const data = pubDiscussions.data.map(
          (resData: {
            status: { emoji: string; name: string };
            deadline: string;
            title: string;
            id: string;
            discussion_users: any;
          }) => {
            let owner: any;

            resData.discussion_users?.forEach((discussionUser: any) => {
              if (discussionUser.isOwner) {
                owner = discussionUser.user;
              }
            });

            return {
              status: `${resData?.status?.emoji} ${resData?.status?.name}`,
              deadline: resData.deadline,
              title: resData.title,
              id: resData.id,
              photo: owner?.photo_address,
              name: owner?.name,
            };
          },
        );
        setPublicDiscussions(data);
      }
      setLoadingDiscussions(true);
    },
    [authInfo.user.id],
  );

  return (
    <FilterProvider>
      <GridContainer>
        {/* Filters */}
        <FilterSection>
          <Filter />
        </FilterSection>
        {/* Discussions */}
        <DiscussionsContainer>
          {/* Discussion Header */}
          <DiscussionsContainerHeader>
            <DiscussionsContainerHeaderContainer>
              <DiscussionsContainerTitle>
                Casos participantes
              </DiscussionsContainerTitle>
              <Link to={'/criar-discussao'}>
                <DiscussionsContainerButton>
                  Abrir nova discussão
                </DiscussionsContainerButton>
              </Link>
            </DiscussionsContainerHeaderContainer>
            <Divisor />
          </DiscussionsContainerHeader>
          {/* Discussions list */}
          <DiscussionsList>
            <ListCasesDiscussion
              cases={casesDiscussion}
              loadCases={loadDiscussions}
              loadingDiscussions={LoadingDiscussions}
            />
          </DiscussionsList>
          {/* Discussion Header */}
          <DiscussionsContainerHeader>
            <DiscussionsContainerHeaderContainer>
              <DiscussionsContainerTitle>
                Discussões públicas
              </DiscussionsContainerTitle>
            </DiscussionsContainerHeaderContainer>
            <Divisor />
          </DiscussionsContainerHeader>
          {/* Public discussions list */}
          <DiscussionsList>
            <ListCasesDiscussion
              cases={publicDiscussions}
              loadCases={loadDiscussions}
              loadingDiscussions={LoadingDiscussions}
            />
          </DiscussionsList>
        </DiscussionsContainer>
      </GridContainer>
    </FilterProvider>
  );
};

export default CasesDiscussion;
