import styled from 'styled-components';
import { ModalContent } from '../GlobalModalContentStyle';

export const EditPhotoModalContent = styled(ModalContent)``;

export const EditPhotoButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-content: center;
`;

export const EditPhotoIconContainer = styled.div`
  cursor: pointer;
  svg {
    height: 2.4rem;
    cursor: pointer;

    path {
      fill: #c9c9c9;
      fill-opacity: 1;
    }
  }
`;

export const EditPhotoButton = styled.button`
  position: relative;
  padding: 4rem;
  border: 0.1rem solid #c9c9c9;
  border-radius: 0.5rem;
  background-color: #fafafa;

  display: grid;
  align-items: center;
  grid-column-gap: 2rem;
  grid-template-columns: auto auto;

  font: ${props => props.theme.font.mediumText};
  color: #c9c9c9;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    transition: 0.2s;
    color: ${props => props.theme.color.hoverBrown};

    ${EditPhotoIconContainer} {
      path {
        fill: ${props => props.theme.color.hoverBrown};
      }
    }
  }
`;
