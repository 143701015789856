import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${props => props.theme.color.white};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }

  transition: background-color 0.1s ease-in;
`;

export const Title = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
`;

export const SubTitle = styled.p`
  font: ${props => props.theme.font.smallText};
  color: ${props => props.theme.color.blue};
  margin: 1rem 0;
  max-width: 14rem;
`;

export const ReplyContainer = styled.div`
  transform: scaleX(-1);
  margin-bottom: 1rem;
`;

export const QaContainer = styled.div`
  margin-bottom: 1rem;
`;

export const TimelineContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: -0.5rem;
`;
