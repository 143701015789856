import styled from 'styled-components';

export const GridContainer = styled.div`
  max-width: 56%;
  margin: 0 auto;
  grid-gap: 1.5rem;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'card1 card2 messages messages'
    'myPatients myPatients cases cases'
    'myPatients myPatients cases cases';
  margin-top: 5rem;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  padding: 1.5rem;
  grid-area: messages;
  background-color: ${props => props.theme.color.white};
`;

export const MessagesTitle = styled.p`
  font: ${props => props.theme.font.mediumText};
  margin-bottom: 1rem;
  color: ${props => props.theme.color.blue};
`;

export const MessagesWarn = styled.p`
  font: ${props => props.theme.font.text};
  margin-top: 5rem;
`;

export const EmptyList = styled.p`
  font: ${props => props.theme.font.text};
  position: relative;
  text-align: center;
  margin-top: 12rem;
`;

export const MyPatientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  padding: 1.5rem;
  grid-area: myPatients;
  background-color: ${props => props.theme.color.white};
  max-height: 35rem;
`;

export const ListPatients = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow: auto;
`;

export const ListCases = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow: auto;
  overflow-x: hidden;
`;

export const MyPatientsTitle = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
  margin-bottom: 1rem;
`;

export const CasesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  padding: 1.5rem;
  grid-area: cases;
  background-color: ${props => props.theme.color.white};
  max-height: 35rem;
`;

export const CasesTitle = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
  margin-bottom: 1rem;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 9rem;
`;
