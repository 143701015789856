import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

import GlobalStyle from './assets/styles/global';

import { CustomThemeProvider } from './assets/styles/CustomThemeContext';
import AppProvider from './hooks';

const App: React.FC = () => (
  <BrowserRouter>
    <CustomThemeProvider>
      <AppProvider>
        <Routes />
      </AppProvider>
      <GlobalStyle />
    </CustomThemeProvider>
  </BrowserRouter>
);

export default App;
