/* eslint no-restricted-globals:0 */
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import Loader, { ThreeDots } from 'react-loader-spinner';
import api from '../../services/api';
import {
  GridContainer,
  MessagesContainer,
  MessagesTitle,
  MessagesWarn,
  MyPatientsContainer,
  MyPatientsTitle,
  CasesContainer,
  CasesTitle,
  ListPatients,
  ListCases,
  EmptyList,
  LoaderContainer,
} from './Home.elements';
import { HomeCard, HomeCase, HomeDivisor, HomePatient } from '../../components';
import { countDeadline } from '../../utils/countDays';
import theme from '../../assets/styles/theme';

interface MyPatientsProps {
  id: string;
  name: string;
  sendBy: string;
  sendBy_surname: string;
  created_at: string;
}

interface DiscussionProps {
  id: string;
  title: string;
  deadline: string;
  status: string;
  photo: string;
}

const Home: React.FC = () => {
  const { ...authInfo } = useAuth();
  const [MyPatients, setMyPatients] = useState<MyPatientsProps[]>(() => {
    return [] as MyPatientsProps[];
  });
  const [Discussion, setDiscussion] = useState<DiscussionProps[]>(() => {
    return [] as DiscussionProps[];
  });

  const [LoadingPatients, setLoadingPatients] = useState(false);
  const [LoadingDiscussions, setLoadingDiscussions] = useState(false);

  const loadMyPatients = async () => {
    const res = await api.get(`/patients-users-doctor-id/${authInfo.user.id}`);
    if (res && res.data) {
      const data = res.data.map(
        (myPatient: {
          id: string;
          patient: { name: string };
          created_at: string;
          forwarder: { name: string; surname: string };
        }) => ({
          id: myPatient.id,
          name: myPatient.patient.name,
          sendBy: myPatient.forwarder.name,
          sendBy_surname: myPatient.forwarder.surname,
          created_at: myPatient.created_at,
        }),
      );
      setMyPatients(
        data.sort(function (a, b) {
          return a.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') <
            b.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
            ? -1
            : a.name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '') >
              b.name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            ? 1
            : 0;
        }),
      );
      setLoadingPatients(true);
    }
  };

  const loadDiscussion = async () => {
    const res = await api.get(
      `/discussions-users-doctor-id/${authInfo.user.id}/?dateOrder=DESC`,
    );
    if (res && res.data) {
      const data = [] as any[];
      res.data.forEach(
        (myDiscussion: {
          id: string;
          status: { emoji: string; name: string };
          deadline: string;
          title: string;
          isPublic: boolean;
          discussion_users: any;
        }) => {
          if (!myDiscussion.isPublic || myDiscussion.isPublic == null) {
            let owner: any;

            myDiscussion.discussion_users?.forEach((discussionUser: any) => {
              if (discussionUser.isOwner) {
                owner = discussionUser.user;
              }
            });

            data.push({
              id: myDiscussion.id,
              title: myDiscussion.title,
              deadline: myDiscussion.deadline,
              status: `${myDiscussion?.status?.emoji} ${myDiscussion?.status?.name}`,
              photo: owner?.photo_address,
            });
          }
        },
      );
      setDiscussion(data);
      setLoadingDiscussions(true);
    }
  };

  useEffect(() => {
    loadMyPatients();
    loadDiscussion();
  }, []);

  return (
    <>
      <GridContainer>
        {/* Cards */}
        {authInfo.user.isSonhe ? (
          <HomeCard
            title="Dar feedback ao encaminhador"
            subtitle="Atualizar as informações e o status de um paciente para o encaminhador."
            redirectTo={'/em-construção'}
            card1
          />
        ) : (
          <HomeCard
            title="Encaminhar meu paciente"
            subtitle="Encaminhe seu paciente para um dos médicos do SOnHe."
            redirectTo={'/em-construção'}
            card1
          />
        )}
        <HomeCard
          title="Abrir caso para discussão"
          subtitle="Começar a discussão de um caso clínico com médicos da sua escolha."
          redirectTo={'/criar-discussao'}
          card2
        />

        {/* Mensagens Privadas */}
        <MessagesContainer>
          <MessagesTitle> Mensagens privadas </MessagesTitle>
          <HomeDivisor />
          <MessagesWarn> Sem mensagens </MessagesWarn>
        </MessagesContainer>

        {/* Pacientes */}
        <MyPatientsContainer>
          <MyPatientsTitle> Meus Pacientes </MyPatientsTitle>
          <HomeDivisor />
          {LoadingPatients ? (
            <ListPatients>
              {MyPatients?.map(
                ({ name, sendBy, sendBy_surname, id, created_at }) => (
                  <HomePatient
                    key={id}
                    title={name}
                    sendBy={
                      authInfo.user.isSonhe
                        ? `${sendBy + ' ' + sendBy_surname}`
                        : `${new Date(created_at).getDate() + 1} dia(s)`
                    }
                    redirectTo={`/paciente/${id}`}
                  />
                ),
              )}
              {!(MyPatients.length > 0) && (
                <EmptyList> Sem pacientes </EmptyList>
              )}
            </ListPatients>
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </MyPatientsContainer>

        {/* Casos */}
        <CasesContainer>
          <CasesTitle> Discussão de casos </CasesTitle>
          <HomeDivisor />
          {LoadingDiscussions ? (
            <ListCases>
              {Discussion?.map(({ id, title, deadline, status, photo }) => (
                <HomeCase
                  key={id}
                  deadline={
                    countDeadline(deadline) > 0
                      ? `${countDeadline(deadline)} dia(s)`
                      : countDeadline(deadline) < 0
                      ? 'Encerrado'
                      : 'Hoje'
                  }
                  status={status}
                  title={title}
                  redirectTo={`/discussao/${id}`}
                  photo={photo}
                />
              ))}
              {!(Discussion.length > 0) && (
                <EmptyList> Sem discussões </EmptyList>
              )}
            </ListCases>
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </CasesContainer>
      </GridContainer>
    </>
  );
};

export default Home;
