import styled from 'styled-components';
import {
  ModalButton,
  ModalContent,
  ModalTitle,
} from '../GlobalModalContentStyle';

export const DeleteAccountModalContent = styled(ModalContent)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-row-gap: 4rem;
  grid-template-areas:
    'deleteAccountTitle'
    'deleteAccountDescription'
    'deleteAccountContainer';
`;

export const DeleteAccountTitle = styled(ModalTitle)`
  font: ${props => props.theme.font.mediumText};
  grid-area: deleteAccountTitle;
`;

export const DeleteAccountDescription = styled.p`
  font: ${props => props.theme.font.text};
  color: rgb(31, 31, 31, 0.8);
  white-space: pre-line;
  grid-area: deleteAccountDescription;
  max-width: 40rem;
`;

export const DeleteAccountButtonsContainer = styled.div`
  grid-area: deleteAccountContainer;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  grid-column-gap: 2rem;
`;

export const DeleteAccountButton = styled(ModalButton)``;
