import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import {
  Home,
  Login,
  Patient,
  UnderConstruction,
  CasesDiscussion,
  Profile,
  RecoverPassword,
  SpecificCase,
  OpenCaseForDiscussion,
} from '../pages';

const Routes: React.FC = () => (
  <Switch>
    <PrivateRoute path="/" exact component={Home} isPrivate />
    <Route path="/login" component={Login} />
    <Route path="/recuperar-senha" component={RecoverPassword} />
    <PrivateRoute
      path="/discussao-casos"
      component={CasesDiscussion}
      isPrivate
    />
    <PrivateRoute
      path="/discussao/:discussionId"
      component={SpecificCase}
      isPrivate
    />
    <PrivateRoute
      path="/criar-discussao/:patientId"
      component={OpenCaseForDiscussion}
      isPrivate
    />
    <PrivateRoute
      path="/criar-discussao"
      component={OpenCaseForDiscussion}
      isPrivate
    />
    <PrivateRoute
      path="/editar-discussao/:discussionId/:isOwner"
      component={OpenCaseForDiscussion}
      isPrivate
    />
    <PrivateRoute path="/perfil" component={Profile} isPrivate />
    <PrivateRoute
      path="/em-construção"
      component={UnderConstruction}
      isPrivate
    />
    <PrivateRoute
      path="/paciente/:patientUserId"
      component={Patient}
      isPrivate
    />
    <Route>
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default Routes;
