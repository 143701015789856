import styled from 'styled-components';
import { PlusCircleFilled } from '@ant-design/icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', pt);

export const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: relative;
  height: calc(100% - 50px);
  width: 100%;
`;

export const CustomDatePicker = styled(DatePicker)`
  font: ${props => props.theme.font.normalText};
  color: ${props => props.theme.color.black};
  border: 0.5px solid #c9c9c9;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  height: 4.3rem;
  width: 24.2rem;
`;

export const PText = styled.p`
  font: ${props => props.theme.font.featuredNormalText};
  margin: 0;
`;

export const Board = styled.div`
  margin-top: 10rem;
  background-color: white;
  width: 55%;
  min-height: 60rem;
  border-radius: 0.5rem;
  padding: 2.5rem 2rem;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-row-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 5rem 5rem 8rem 3fr 8rem;
  grid-template-areas:
    'first-line first-line first-line'
    'second-line second-line second-line'
    'third-line third-line third-line'
    'fourth-line fourth-line fourth-line'
    'fifth-line fifth-line fifth-line';
`;

export const WrapperEditor = styled.div`
  grid-area: fourth-line;
`;

export const FirstLineContainer = styled.div`
  grid-area: first-line;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const PatientDiscussion = styled.select`
  height: 4.3rem;
  width: 24.2rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
  display: flex;
`;

export const ProfileButtonContainer = styled.div`
  position: relative;
  padding: 1rem 1.5rem;
  border-radius: 5rem;
  width: 24.6rem;
  height: 4.3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
`;

export const ImageButtonContainer = styled.div`
  display: flex;
  justify-self: flex-end;
  align-items: center;
  position: relative;
  grid-area: second-line;
`;

export const ProfileImageContainer = styled.div`
  position: absolute;
  left: 5rem;
  top: 0;
`;

export const CheckPublicDiscussionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    margin-left: 4px;
    font: ${props => props.theme.font.normalText};
  }
`;

export const ProfileImage = styled.img`
  max-width: 100%;
  min-width: 4.4rem;
  min-height: 4.4rem;
  max-height: 4.4rem;
  border-radius: 50%;
  margin-left: -7.3rem;
`;

export const MoreDoctors = styled.div`
  max-width: 100%;
  min-width: 4.7rem;
  min-height: 4.7rem;
  max-height: 4.7rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 59rem;

  background-color: ${props => props.theme.color.mainBrown};
  background-repeat: no-repeat;
  position: absolute;
  z-index: 100;
`;

export const MoreDoctorsText = styled.text`
  font-size: 15px;
  color: #ffffff;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PlusIcon = styled(PlusCircleFilled)`
  font-size: 2rem;
  margin-right: 0.8rem;
`;

export const AddPatientsButton = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 5rem;
  width: 24.6rem;
  height: 4.3rem;
  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
`;

export const Title = styled.input`
  width: 100%;
  height: 7.3rem;
  border: 0.5px solid ${props => props.theme.color.inputBorder};
  color: rgb(31, 31, 31, 0.8);
  border-radius: 0.5rem;
  grid-area: third-line;
  padding: 1rem 2rem;
  font: ${props => props.theme.font.modalTitle};
`;

export const SubmitButton = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  grid-area: fifth-line;
  width: 18.8rem;
  height: 3.7rem;
  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
`;
