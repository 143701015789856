import styled from 'styled-components';

interface NavTextProps {
  selected?: boolean;
}

export const NavItemContainer = styled.div`
  width: fit-content;
  padding: 1.5rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }

  transition: background-color 0.1s ease-in;
`;

export const NavText = styled.p`
  font: ${props => props.theme.font.normalText};
  font-weight: ${(props: NavTextProps) => (props.selected ? 700 : 400)};
  color: white;
  text-transform: uppercase;
  text-decoration: none;
`;
