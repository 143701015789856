export const countTimeSince = (deadline: string): string => {
  const today = new Date();
  const discussionDeadline = new Date(deadline);
  if (today.getMonth() == 11 && today.getDate() > 25) {
    discussionDeadline.setFullYear(discussionDeadline.getFullYear() + 1);
  }
  const day = 1000 * 60 * 60 * 24;
  const hour = 1000 * 60 * 60;
  const minute = 1000 * 60;

  const time = today.getTime() - discussionDeadline.getTime();

  let result;
  let timePeriod;

  if (time >= day) {
    result = Math.ceil(time / day);
    timePeriod = 'd';
  } else if (time >= hour) {
    result = Math.ceil(time / hour);
    timePeriod = 'h';
  } else {
    result = Math.ceil(time / minute);
    timePeriod = 'min';
  }

  return String(result + ' ' + timePeriod);
};
