import styled from 'styled-components';
import {
  ModalButton,
  ModalContent,
  ModalTitle,
} from '../GlobalModalContentStyle';

export const SignOutModalContent = styled(ModalContent)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-row-gap: 4rem;
  grid-template-areas:
    'signOutTitle'
    'signOutDescription'
    'signOutContainer';
`;

export const SignOutTitle = styled(ModalTitle)`
  font: ${props => props.theme.font.mediumText};
  grid-area: signOutTitle;
`;

export const SignOutDescription = styled.p`
  font: ${props => props.theme.font.text};
  color: rgb(31, 31, 31, 0.8);
  max-width: 40rem;
  white-space: pre-line;
  grid-area: signOutDescription;
`;

export const SignOutButtonsContainer = styled.div`
  grid-area: signOutContainer;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  grid-column-gap: 2rem;
`;

export const SignOutButton = styled(ModalButton)``;
