import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface User {
  id: string;
  name: string;
  surname: string;
  speciality: string;
  photo_address: string;
  bio: string;
  isSonhe: boolean;
}

interface AuthState {
  user: User;
  token: string;
}
interface SignInCredentials {
  email: string;
  password: string;
}

interface SignUpCredentials {
  name: string;
  email: string;
  confirm_email: string;
  password: string;
  confirm_password: string;
  avatar_url: string;
  specialty: string;
  crm: string;
}

interface AuthContextProps {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signUp(credentials: SignUpCredentials): Promise<void>;
  updateUser(user: User): void;
  signOut(): void;
}
const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@SOnHe:user');
    const token = localStorage.getItem('@SOnHe:token');

    if (user && token) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { user: JSON.parse(user), token };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('user-auth', { email, password });

    const { user, token } = response.data;

    localStorage.setItem('@SOnHe:user', JSON.stringify(user));
    localStorage.setItem('@SOnHe:token', token);

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ user, token });
  }, []);

  const signUp = useCallback(async (userData: SignUpCredentials) => {
    await api.post('users', userData);
  }, []);

  const updateUser = useCallback(
    (userData: User) => {
      localStorage.setItem('@SOnHe:user', JSON.stringify(userData));

      setData({
        token: data.token,
        user: userData,
      });
    },
    [setData, data.token],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@SOnHe:user');
    localStorage.removeItem('@SOnHe:token');

    delete api.defaults.headers.authorization;

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signUp, updateUser, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
