import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../hooks/auth';

import {
  SignOutModalContent,
  SignOutTitle,
  SignOutDescription,
  SignOutButtonsContainer,
  SignOutButton,
} from './SignOut.elements';

interface ISignOutProps {
  modalAction: () => void;
}

export const SignOut: React.FC<ISignOutProps> = ({
  modalAction,
}: ISignOutProps) => {
  const { signOut } = useAuth();
  return (
    <SignOutModalContent>
      <SignOutTitle>
        Você tem certeza que deseja sair da sua conta ?
      </SignOutTitle>
      <SignOutDescription>
        Ao sair da sua conta você será redirecionado para a tela de login. Tem
        certeza que deseja continuar com esta ação?
      </SignOutDescription>
      <SignOutButtonsContainer>
        <SignOutButton cancel={true} onClick={modalAction}>
          Não
        </SignOutButton>
        <Link to="/login">
          <SignOutButton
            onClick={() => {
              signOut();
              modalAction();
            }}
          >
            Sim
          </SignOutButton>
        </Link>
      </SignOutButtonsContainer>
    </SignOutModalContent>
  );
};

export default SignOut;
