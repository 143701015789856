import React, { ChangeEvent, useCallback } from 'react';
import {
  EditPhotoButton,
  EditPhotoButtonsContainer,
  EditPhotoIconContainer,
  EditPhotoModalContent,
} from './EditPhoto.elements';

import { Gallery } from '../../../../assets/icons';

interface IEditPhotoProps {
  modalAction: () => void;
  changePhoto: (previewAvatar: string, avatar: File) => void;
}

const EditPhoto: React.FC<IEditPhotoProps> = ({
  modalAction,
  changePhoto,
}: IEditPhotoProps) => {
  const handleAvatarChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const reader = new FileReader();
        const target = e.target.files[0];
        reader.readAsDataURL(e.target.files[0]);

        reader.onloadend = () => {
          changePhoto(String(reader?.result), target);
          modalAction();
        };
      }
    },
    [changePhoto, modalAction],
  );

  return (
    <EditPhotoModalContent>
      <EditPhotoButtonsContainer>
        <EditPhotoButton>
          <EditPhotoIconContainer>
            <Gallery />
          </EditPhotoIconContainer>
          <input type="file" onChange={handleAvatarChange} />
          Escolher do computador
        </EditPhotoButton>
      </EditPhotoButtonsContainer>
    </EditPhotoModalContent>
  );
};

export default EditPhoto;
