import React, { FormEvent, useState } from 'react';
import {
  GridContainer,
  LoginContainer,
  LoginTitle,
  LogoContainer,
  Container,
} from '../Login/Login.elements';
import api from '../../services/api';
import { LogoLogin } from '../../assets/icons';
import { useHistory } from 'react-router-dom';
import LoginForm from '../Login/LoginForm/LoginForm';

interface LoginFormData {
  password: string;
  newPassword: string;
}

const RecoverPassword: React.FC = () => {
  const history = useHistory();
  const [isWrong, setIsWrong] = useState(false);

  const handleSubmit = async (e: FormEvent, formData: LoginFormData) => {
    try {
      const params = window.location.search;
      const urlParams = new URLSearchParams(params);
      const token = urlParams.get('token');
      const email = urlParams.get('email');

      e.preventDefault();
      const requiredFields = ['password', 'newPassword'];

      for (const field of requiredFields) {
        if (!formData[field as keyof LoginFormData]) {
          setIsWrong(true);
          return;
        }
      }

      const { password, newPassword } = formData;

      if (
        password != newPassword ||
        password.length < 8 ||
        password.includes(' ')
      ) {
        setIsWrong(true);
        return;
      } else {
        try {
          await api.post(`/reset-password`, {
            email,
            token,
            password,
          });
        } catch (error) {
          console.log(error);
        }
        history.push('/');
      }
    } catch (error) {
      setIsWrong(true);
    }
  };

  return (
    <Container>
      <GridContainer>
        <LogoContainer>
          <LogoLogin />
        </LogoContainer>
        <LoginContainer>
          <LoginTitle>Recuperação de Senha - SOnHe Web</LoginTitle>
          <LoginForm
            forgotPassword={false}
            handle={handleSubmit}
            isWrong={isWrong}
            handleIsWrong={setIsWrong}
          />
        </LoginContainer>
      </GridContainer>
    </Container>
  );
};

export default RecoverPassword;
