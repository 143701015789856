import styled from 'styled-components';
import React, { HTMLAttributes } from 'react';

interface PhotoProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  photo: string;
}

export const Container = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  justify-content: flex-end;
`;

export const Photo = styled.div<PhotoProps>`
  height: 4rem;
  width: 4rem;
  border-radius: 3rem;
  align-self: flex-end;

  background-image: url(${props => props.photo});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const InsideContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.white};
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  padding: 1.5rem;
  margin-left: 1rem;
`;

export const CommentBody = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
  margin-bottom: 0.5rem;
  user-select: text;
`;

export const CommentImage = styled.img`
  max-width: 30rem;
  max-height: 30rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const CommentImageModal = styled.img`
  max-width: 128rem;
  max-height: 76.8rem;
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Time = styled.p`
  font: ${props => props.theme.font.smallText};
  color: ${props => props.theme.color.black};
  margin-right: 1rem;
`;

export const Date = styled.p`
  font: ${props => props.theme.font.smallText};
  color: ${props => props.theme.color.black};
`;
