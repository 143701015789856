import React from 'react';

import {
  Container,
  NotificationPhoto,
  ItemsContainer,
  NotificationText,
  Deadline,
  Triangle,
} from './NotificationItem.elements';

import { NotificationProps } from '../NotificationBox';
import { useHistory } from 'react-router-dom';

const NotificationItem: React.FC<NotificationProps> = ({
  photo,
  title,
  deadline,
  description,
  discussionId,
}) => {
  const history = useHistory();

  const redirectTo = () => {
    history.replace(`discussao/${discussionId}`);
  };

  return (
    <>
      <Triangle />
      <Container onClick={redirectTo}>
        <NotificationPhoto photo={photo} />
        <ItemsContainer>
          <NotificationText>
            {' '}
            <strong>{title}</strong> <strong>{description}</strong>
          </NotificationText>
          <Deadline> {deadline} </Deadline>
        </ItemsContainer>
      </Container>
    </>
  );
};

export default NotificationItem;
