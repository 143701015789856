const TIME_ZONE = 'America/Sao_Paulo';

const defaultF = (date: string) => {
  const time = new Date(date);
  const hours = time.getHours();
  time.setHours(hours - 3); // UTC -> PT-BR

  return time;
};

/* === Desativado === */
// const toISOFormat = (dateTimeString: string): string => {
//   // Primeiro, dividimos a data completa em duas partes:
//   const [date, time] = dateTimeString.split(' ');

//   // Dividimos a data em dia, mês e ano:
//   const [DD, MM, YYYY] = date.split('/');

//   // Dividimos o tempo em hora e minutos:
//   const [HH, mm] = time.split(':');

//   // Retornamos a data formatada em um padrão compatível com ISO:
//   return `${YYYY}-${MM}-${DD}T${HH}:${mm}`;
// };

const convertToPostgresDate = (date: Date): string => {
  return new Date(date).toISOString();
};

const hoursAndMinutes = (date: string): string => {
  const dTime = defaultF(date);

  return new Intl.DateTimeFormat('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: TIME_ZONE,
  }).format(dTime);
};

const onlyDay = (date: string): string => {
  const dTime = defaultF(date);

  return new Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    timeZone: TIME_ZONE,
  }).format(dTime);
};

const dayAndMonth = (date: string, monthStyle: any): string => {
  const dTime = defaultF(date);

  return new Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: monthStyle, // 'short', 'numeric', 'long'
    timeZone: TIME_ZONE,
  }).format(dTime);
};

const fullDate = (date: string, monthStyle?: any): string => {
  const dTime = defaultF(date);

  return new Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: monthStyle, // 'short', 'numeric', 'long'
    year: 'numeric',
    timeZone: TIME_ZONE,
  }).format(dTime);
};

export {
  convertToPostgresDate,
  onlyDay,
  dayAndMonth,
  fullDate,
  hoursAndMinutes,
};
