/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { useTheme } from '../../../../assets/styles/CustomThemeContext';
import {
  ChangeFontSizeButton,
  ChangeFontSizeButtonsContainer,
  ChangeFontSizeContainer,
  ChangeFontSizeModalContent,
  ChangeFontSizeOption,
  ChangeFontSizeTitle,
} from './ChangeFontSize.elements';

interface IChangeFontSizeProps {
  modalAction: () => void;
}

const ChangeFontSize: React.FC<IChangeFontSizeProps> = ({
  modalAction,
}: IChangeFontSizeProps) => {
  const { toggleFontSize, fontSize } = useTheme();
  const [defaultFontSize, setDefaultFontSize] = useState(fontSize);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFontSize = (e: any) => {
    setDefaultFontSize(Number(e.target.id));
  };

  const saveChanges = () => {
    toggleFontSize(defaultFontSize);
    window.location.reload();
  };

  return (
    <ChangeFontSizeModalContent>
      <ChangeFontSizeTitle>Altere o tamanho da fonte</ChangeFontSizeTitle>
      <ChangeFontSizeContainer>
        <ChangeFontSizeOption
          id="1"
          fontSize={defaultFontSize}
          onClick={handleFontSize}
        >
          1x
        </ChangeFontSizeOption>
        <ChangeFontSizeOption
          id="1.2"
          fontSize={defaultFontSize}
          onClick={handleFontSize}
        >
          2x
        </ChangeFontSizeOption>
        <ChangeFontSizeOption
          id="1.4"
          fontSize={defaultFontSize}
          onClick={handleFontSize}
        >
          3x
        </ChangeFontSizeOption>
      </ChangeFontSizeContainer>
      <ChangeFontSizeButtonsContainer>
        <ChangeFontSizeButton cancel={true} onClick={modalAction}>
          Cancelar
        </ChangeFontSizeButton>
        <ChangeFontSizeButton
          onClick={() => {
            modalAction();
            saveChanges();
          }}
        >
          Salvar alteração
        </ChangeFontSizeButton>
      </ChangeFontSizeButtonsContainer>
    </ChangeFontSizeModalContent>
  );
};

export default ChangeFontSize;
