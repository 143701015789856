import React from 'react';
import {
  FeedbackForDevelopersModalContent,
  FeedbackForDevelopersTextArea,
  FeedbackForDevelopersTitle,
  FeedbackForDevelopersSelect,
  FeedbackForDevelopersInputsContainer,
  FeedbackForDevelopersButton,
  FeedbackForDevelopersButtonsContainer,
} from './FeedbackForDevelopers.elements';

interface IFeedbackForDevelopersProps {
  modalAction: () => void;
}

const FeedbackForDevelopers: React.FC<IFeedbackForDevelopersProps> = ({
  modalAction,
}: IFeedbackForDevelopersProps) => {
  return (
    <FeedbackForDevelopersModalContent>
      <FeedbackForDevelopersTitle>
        Envie um feedback para os Desenvolvedores
      </FeedbackForDevelopersTitle>
      <FeedbackForDevelopersInputsContainer>
        <FeedbackForDevelopersSelect>
          <option value="" selected disabled>
            Selecione um motivo
          </option>
          <option value="configurações">Configurações</option>
          <option value="cases">Criação de casos</option>
          <option value="other">Outro</option>
        </FeedbackForDevelopersSelect>
        <FeedbackForDevelopersTextArea placeholder="Detalhe seu feedback" />
      </FeedbackForDevelopersInputsContainer>
      <FeedbackForDevelopersButtonsContainer>
        <FeedbackForDevelopersButton onClick={modalAction} cancel={true}>
          Cancelar
        </FeedbackForDevelopersButton>
        <FeedbackForDevelopersButton onClick={modalAction}>
          Enviar
        </FeedbackForDevelopersButton>
      </FeedbackForDevelopersButtonsContainer>
    </FeedbackForDevelopersModalContent>
  );
};

export default FeedbackForDevelopers;
