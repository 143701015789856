import styled from 'styled-components';
import React, { HTMLAttributes } from 'react';

interface PhotoProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  photo: string;
}

export const Container = styled.div`
  display: flex;
  padding: 15px;
  width: 35rem;
  cursor: pointer;
  background-color: ${props => props.theme.color.white};

  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }
  transition: background-color 0.1s ease-in;
`;

export const Triangle = styled.div`
  position: absolute;
  top: -10px;
  left: 5.5rem;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-bottom: 20px solid white;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const NotificationPhoto = styled.div<PhotoProps>`
  height: 5rem;
  width: 5rem;
  min-height: 5rem;
  min-width: 5rem;
  border-radius: 3rem;
  align-self: flex-end;

  background-image: url(${props => props.photo});
  background-size: 5rem;
`;
export const ItemsContainer = styled.div`
  margin-left: 15px;
`;
export const Person = styled.p`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
`;
export const NotificationText = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const Deadline = styled.p`
  font: ${props => props.theme.font.text};
  color: #828282;
  margin-top: 2px;
`;
