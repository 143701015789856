/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link } from 'react-router-dom';
import { OptionContainer, OptionsTitle } from './PatientOptions.elements';

type PatientOptionsProps = {
  openModal?: () => void;
  title: string;
  icon: any;
};

const PatientOptions: React.FC<PatientOptionsProps> = ({
  openModal,
  title,
  icon: Component,
}) => {
  return (
    <OptionContainer onClick={openModal}>
      <Component />
      <OptionsTitle>{title}</OptionsTitle>
    </OptionContainer>
  );
};

export default PatientOptions;
