import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import RichText from './RichText/RichText';

import {
  Board,
  Container,
  WrapperEditor,
  CustomDatePicker,
  Title,
  SubmitButton,
  ProfileImage,
  ProfileButtonContainer,
  PlusIcon,
  TextContainer,
  PText,
  ProfileImageContainer,
  ImageButtonContainer,
  PatientDiscussion,
  FirstLineContainer,
  CheckPublicDiscussionContainer,
  MoreDoctors,
  MoreDoctorsText,
} from './OpenCaseForDiscussion.elements';
import AddParticipant from './ModalContents/AddParticipant/AddParticipant';
import { Modal } from '../../components';
import { convertToPostgresDate } from '../../utils/dateFunctions';
import { LoaderContainer } from '../Home/Home.elements';
import Loader, { ThreeDots } from 'react-loader-spinner';
import theme from '../../assets/styles/theme';

interface ParamTypes {
  discussionId: string;
  patientId?: string;
  isOwner: string;
}

const OpenCaseForDiscussion: React.FC = () => {
  const history = useHistory();
  const { patientId, discussionId, isOwner } = useParams<ParamTypes>();
  const [contentEditor, setContentEditor] = useState('');
  const [loading, setLoading] = useState(false);
  const [userPatients, setUserPatients] = useState<any>([]);
  const [selectedPatient, setSelectedPatient] = useState(() => {
    return patientId ? patientId : '';
  });
  const [discussionUsers, setDiscussionUsers] = useState<any>();
  const [newParticipants, setNewParticipants] = useState<any>();
  const [removedDiscussionUsers, setRemovedDiscussionUsers] = useState<any>([]);
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [publicDiscussion, setPublicDiscussion] = useState(false);
  const [discussionUsersPhotos, setDiscussionUsersPhotos] = useState<any>([]);
  const [deadline, setDeadline] = useState<any>('');
  const [allStatus, setAllStatus] = useState(() => {
    return [] as any[];
  });

  const openOrCloseModal = () => {
    setShowModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  const loadDiscussion = async (discussionId: string) => {
    setLoading(true);
    const res = await api.get(`/discussions/${discussionId}`);
    const dUsers = res.data.discussion_users.map((ds: any) => ds.user);
    const aux = dUsers;
    if (aux.length > 6) {
      setDiscussionUsersPhotos(aux.slice(0, 6));
    } else {
      setDiscussionUsersPhotos(aux);
    }
    setTitle(res.data.title);
    const date = new Date(res.data.deadline);
    setDeadline(date);
    setContentEditor(res.data.description);
    setSelectedPatient(res.data.patient_id);
    setDiscussionUsers(dUsers);
    setPublicDiscussion(res.data.isPublic !== null ? res.data.isPublic : false);

    setLoading(false);
  };

  const loadAllStatus = async () => {
    const res = await api.get('discussions-status');
    setAllStatus(res.data);
  };

  const createDiscussion = async () => {
    if (
      contentEditor !== '' &&
      contentEditor !== null &&
      deadline !== '' &&
      deadline !== null &&
      title !== '' &&
      title !== undefined &&
      title !== null
    ) {
      setLoading(true);
      let defaultStatus = '';
      allStatus.forEach((st: { name: string; id: string }) => {
        if (st.name === 'Em discussão') {
          defaultStatus = st.id;
        }
      });
      if (defaultStatus !== '') {
        const date = new Date(deadline);
        date.setDate(date.getDate() + 1);

        date.setHours(0, 0, 0);
        let resData: any;
        if (selectedPatient != '' && selectedPatient != null) {
          const res = await api.post('discussions', {
            title: title,
            deadline: convertToPostgresDate(date),
            description: contentEditor,
            isPublic: publicDiscussion,
            status_id: defaultStatus,
            patient_id: selectedPatient,
          });
          resData = res;
        } else {
          const res = await api.post('discussions', {
            title: title,
            deadline: convertToPostgresDate(date),
            description: contentEditor,
            isPublic: publicDiscussion,
            status_id: defaultStatus,
          });
          resData = res;
        }
        if (resData.data != null && !publicDiscussion) {
          await associateWithOwnerUser(resData.data.id);

          if (newParticipants?.length) {
            await addParticipantsOnDiscussions(resData.data.id);
          }
        }
        setTimeout(() => {
          history.push(`/discussao/${resData.data.id}`);
        }, 1000);
      }
    } else {
      alert('Todos os campos devem estar preenchidos!');
    }
  };

  const updateDiscussion = async () => {
    if (
      (contentEditor !== '' && contentEditor !== null) ||
      (deadline !== '' && deadline !== null) ||
      (title !== '' && title !== undefined && title !== null)
    ) {
      setLoading(true);
      await api.put(`discussions/${discussionId}`, {
        title: title,
        patient_id: selectedPatient !== '' ? selectedPatient : null,
        isPublic: publicDiscussion,
        deadline: convertToPostgresDate(deadline),
        description: contentEditor,
      });

      if (newParticipants?.length && !publicDiscussion) {
        await addParticipantsOnDiscussions(discussionId);
      }

      if (removedDiscussionUsers?.length && !publicDiscussion) {
        await removeParticipants();
      }

      setTimeout(() => {
        history.push(`/discussao/${discussionId}`);
      }, 1000);
    }
  };

  const addParticipantsOnDiscussions = async (discussionId: string) => {
    newParticipants.forEach(async (ds: { id: string }) => {
      await api.post('/discussions-users', {
        discussion_id: discussionId,
        user_id: ds?.id,
        isOwner: ds?.id === user.id && isOwner,
      });
    });
  };

  const loadPatients = async () => {
    const res = await api.get(`/patients-users-doctor-id/${user.id}`);

    if (res && res.data) {
      const data = res.data.map(
        (patient: {
          patient: { id: string; name: string };
          forwarder: { name: string };
        }) => ({
          id: patient.patient.id,
          name: patient.patient.name,
          forwarder: patient.forwarder.name,
        }),
      );
      setUserPatients(data);
    }
  };

  const removeParticipants = async () => {
    removedDiscussionUsers.forEach(async (ds: any) => {
      await api.delete(`/discussions-users-doctor-id/${ds}`);
    });
  };

  const associateWithOwnerUser = async (discussionId: string) => {
    await api.post('/discussions-users', {
      discussion_id: discussionId,
      user_id: user.id,
      isOwner: true,
    });
  };

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate && currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    if (discussionId) {
      loadDiscussion(discussionId);
    }
    loadPatients();
    loadAllStatus();
  }, []);

  return (
    <>
      {discussionId && loading ? (
        <LoaderContainer>
          <ThreeDots color={theme.color.mainBrown} height={100} width={100} />
        </LoaderContainer>
      ) : (
        <>
          <Container>
            <Board>
              <FirstLineContainer>
                <CustomDatePicker
                  placeholderText="Selecione um prazo"
                  selected={deadline}
                  locale="pt-BR"
                  onChange={(date: Date) => setDeadline(date)}
                  dateFormat="P"
                  filterDate={filterPassedTime}
                />
                <PatientDiscussion
                  onChange={e => setSelectedPatient(e.target.value)}
                >
                  {!discussionId ? (
                    <>
                      {!selectedPatient && (
                        <option value="" selected>
                          Selecione um paciente
                        </option>
                      )}

                      {userPatients?.map((patient: any) => (
                        <option
                          key={patient?.id}
                          value={patient.id}
                          selected={
                            patient?.id == patientId ||
                            patient?.id == selectedPatient
                          }
                        >
                          {patient.name}
                        </option>
                      ))}
                    </>
                  ) : (
                    <>
                      <option value="" selected>
                        Selecione um paciente
                      </option>
                      {userPatients?.map((patient: any) => (
                        <option
                          key={patient?.id}
                          value={patient?.id}
                          selected={
                            patient?.id == patientId ||
                            patient?.id == selectedPatient
                          }
                        >
                          {patient.name}
                        </option>
                      ))}
                    </>
                  )}
                </PatientDiscussion>
                <CheckPublicDiscussionContainer>
                  <input
                    type="checkbox"
                    onChange={() => setPublicDiscussion(!publicDiscussion)}
                    checked={publicDiscussion}
                    className="publicDiscussionCheck"
                    name="publicDiscussion"
                  />
                  <label htmlFor="publicDiscussion">Discussão pública</label>
                </CheckPublicDiscussionContainer>
              </FirstLineContainer>
              {!publicDiscussion && (
                <ImageButtonContainer>
                  <ProfileButtonContainer onClick={openOrCloseModal}>
                    {/* <ProfileImage /> */}
                    <TextContainer>
                      <PlusIcon />
                      <PText>Adicionar Participante</PText>
                    </TextContainer>
                    {discussionUsers?.length > 6 ? (
                      <MoreDoctors>
                        <MoreDoctorsText>
                          +{discussionUsers.length - 6}
                        </MoreDoctorsText>
                      </MoreDoctors>
                    ) : (
                      <></>
                    )}
                  </ProfileButtonContainer>
                  <ProfileImageContainer>
                    {discussionUsersPhotos?.map((ds: any, index: any) => (
                      <ProfileImage key={index} src={ds.photo_address} />
                    ))}
                  </ProfileImageContainer>
                </ImageButtonContainer>
              )}
              <Title
                placeholder="Título"
                value={title}
                onChange={event => setTitle(event.target.value)}
              />
              <WrapperEditor>
                <RichText
                  content={contentEditor}
                  contentAction={setContentEditor}
                />
              </WrapperEditor>
              {discussionId && isOwner ? (
                <SubmitButton onClick={updateDiscussion}>
                  Atualizar discussão
                </SubmitButton>
              ) : (
                <SubmitButton onClick={createDiscussion}>
                  Publicar discussão
                </SubmitButton>
              )}
            </Board>
          </Container>
          {/* Modal Adicionar participante*/}
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <AddParticipant
              modalAction={openOrCloseModal}
              currentParticipants={discussionUsers}
              newParticipants={newParticipants}
              setNewParticipants={setNewParticipants}
              removedParticipants={removedDiscussionUsers}
              setParticipants={setDiscussionUsers}
              setRemovedParticipants={setRemovedDiscussionUsers}
              setParticipantsPhotos={setDiscussionUsersPhotos}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default OpenCaseForDiscussion;
