import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 9rem;
`;

export const NoResultsMessage = styled.h2`
  text-align: center;
  font: ${props => props.theme.font.mediumText};
  margin: 4rem 0 6rem 0;
`;
