import React, {
  Children,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ThemeProvider } from 'styled-components';

interface ThemeContextProps {
  toggleFontSize(fontSize: number): void;
  fontSize: number;
}

interface Theme {
  color: {
    red: string;
    green: string;
    black: string;
    blue: string;
    mainBrown: string;
    secondaryBrown: string;
    hoverBrown: string;
    gray: string;
    secondaryGray: string;
    white: string;
    backgroundGray: string;
    whiteHover: string;
    inputBorder: string;
  };

  font: {
    bigText: string;
    bigTitle: string;
    mediumText: string;
    middleText: string;
    modalTitle: string;
    normalText: string;
    featuredNormalText: string;
    text: string;
    featuredText: string;
    smallText: string;
  };
}

const ThemeContext = createContext<ThemeContextProps>({} as ThemeContextProps);

export const CustomThemeProvider: React.FC = ({ children }) => {
  const [fontSize, setFontSize] = useState(() => {
    const fontSize = localStorage.getItem('@SOnHe:fontSize');

    if (fontSize) {
      return Number(fontSize);
    }

    return 1;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [theme, setTheme] = useState<Theme>({
    color: {
      red: '#EB5757',
      green: '#27AE60',
      black: '#131313',
      blue: '#1F3249',
      mainBrown: '#846550',
      secondaryBrown: '#A78B6B',
      hoverBrown: '#9D826F',
      gray: '#D1D3D4',
      secondaryGray: '#E6E6F3',
      white: '#fff',
      backgroundGray: '#E5E5E5',
      whiteHover: '#F0F0F0',
      inputBorder: '#C9C9C9',
    },

    font: {
      bigText: `normal normal bold ${3.2 * fontSize}rem "Roboto", sans-serif`, // Roboto bold 32px: For titles
      bigTitle: `normal normal bold ${2.4 * fontSize}rem "Roboto", sans-serif`, // Roboto bold 24px: For titles
      mediumText: `normal normal bold ${
        1.8 * fontSize
      }rem "Roboto", sans-serif`, // Roboto bold 18px: For subtitles and other featured texts
      middleText: `normal normal ${1.8 * fontSize}rem "Roboto", sans-serif`,
      modalTitle: `normal normal bold ${
        2.4 * fontSize
      }rem "Roboto", sans-serif`, // Roboto bold 18px: For modal contents title
      normalText: `normal normal normal ${
        1.6 * fontSize
      }rem "Roboto", sans-serif`, // Roboto regular 16px: For navbar, placeholder's and some components description
      featuredNormalText: `normal normal bold ${
        1.6 * fontSize
      }rem "Roboto", sans-serif`, // Roboto regular 16px: For secondary featured texts, like doctor name on "Meus pacientes" component
      text: `normal normal normal ${1.4 * fontSize}rem "Roboto", sans-serif`, // Roboto regular 14px: For body texts
      featuredText: `normal normal bold ${
        1.4 * fontSize
      }rem "Roboto", sans-serif`, // Roboto regular 14px: For featured body texts
      smallText: `normal normal normal ${
        1.2 * fontSize
      }rem "Roboto", sans-serif`, // Roboto regular 12px: For main cards descriptions Ex: Dar feedback ao encaminhador,
    },
  });

  const toggleFontSize = useCallback((fontSize: number) => {
    localStorage.setItem('@SOnHe:fontSize', String(fontSize));
    setFontSize(fontSize);
  }, []);

  return (
    <ThemeContext.Provider value={{ toggleFontSize, fontSize }}>
      <ThemeProvider theme={theme}>
        <>{children}</>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a CustomThemeProvider');
  }

  return context;
};

export default ThemeProvider;
