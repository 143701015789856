import React from 'react';
import { Link } from 'react-router-dom';
import { HomeDivisor } from '../../../components';
import { useAuth } from '../../../hooks/auth';

import {
  Patient,
  PatientTitle,
  TextsContainer,
  SendByText,
  PatientSendBy,
  PatientHover,
} from './HomePatient.elements';

type HomePatientProps = {
  title: string;
  sendBy: string;
  redirectTo: string;
};

const HomePatient: React.FC<HomePatientProps> = ({
  title,
  sendBy,
  redirectTo,
}) => {
  const { user } = useAuth();
  return (
    <>
      <Patient as={Link} to={redirectTo}>
        <PatientHover>
          <PatientTitle> {title} </PatientTitle>
          <TextsContainer>
            <SendByText>
              {user.isSonhe ? 'Encaminhado por' : 'Encaminhado há'}{' '}
            </SendByText>
            <PatientSendBy> {sendBy} </PatientSendBy>
          </TextsContainer>
        </PatientHover>
      </Patient>
      <HomeDivisor />
    </>
  );
};

export default HomePatient;
