import React, { useMemo, useRef } from 'react';
// import { RichTextStyle } from './Rich.elements';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import resize from '../../../utils/compressImage';

interface RichTextProps {
  content: any;
  contentAction: React.Dispatch<React.SetStateAction<string>>;
}

const RichText: React.FC<RichTextProps> = ({
  content,
  contentAction,
}: RichTextProps) => {
  const quill = useRef() as React.MutableRefObject<any>;

  const setOnEditor = (file: any) => {
    if (quill.current != null) {
      const editor = quill.current.getEditor();
      const range = editor.getSelection();

      if (range != null) {
        editor.insertEmbed(range.index, 'image', file);

        editor.setSelection({
          index: Number((range.index += 1)),
          length: 0,
        });
      }
    }
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = e => {
      resize(e, setOnEditor);
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          [
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'code-block',
            'link',
            'image',
          ],
          [{ color: [] }, { background: [] }],
          ['align', { list: 'bullet' }, { list: 'ordered' }],
        ],
        handlers: {
          image: selectLocalImage,
        },
      },
    }),
    [],
  );

  return (
    <>
      <ReactQuill
        ref={quill}
        value={content}
        onChange={event => {
          contentAction(event);
        }}
        modules={modules}
        placeholder="Descreva..."
      />
    </>
  );
};

export default RichText;
