import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface DivisorProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  width?: number;
}

export const Divisor = styled.div<DivisorProps>`
  height: 1px;
  width: 100%;
  background-color: #d1d3d4;
`;
