import React, { FormEvent, useState } from 'react';
import { Modal } from '../../../components';
import { LoginContainer, LoginTitle } from '../Login.elements';
import {
  ErrorMessage,
  ForgetPasswordLink,
  LoginButton,
  LoginFormContainer,
  LoginFormInputsContainer,
  LoginFormOptionsContainer,
  RecoverPasswordMessage,
} from './LoginForm.elements';
import api from '../../../services/api';
import LoginInput from '../LoginInput/LoginInput';

interface LoginFormProps {
  handle: (
    e: FormEvent,
    formData: { email: string; password: string; newPassword: string },
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleIsWrong: any;
  isWrong: boolean;
  forgotPassword: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  handle,
  isWrong,
  handleIsWrong,
  forgotPassword,
}: LoginFormProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [sendEmail, setSendEmail] = useState('');
  const [showModal, setShowModal] = useState(false);

  const ForgotPassword = async () => {
    try {
      await api.post(`/forgot-password`, {
        email: sendEmail,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <LoginFormContainer
        onSubmit={(e: FormEvent) => handle(e, { email, password, newPassword })}
      >
        <LoginFormInputsContainer>
          {forgotPassword && (
            <LoginInput
              placeholderName="Email"
              onChangeAction={setEmail}
              onFocusAction={handleIsWrong}
            />
          )}
          <LoginInput
            placeholderName={
              !forgotPassword ? 'Digite sua nova senha' : 'Senha'
            }
            type="password"
            onChangeAction={setPassword}
            onFocusAction={handleIsWrong}
          />
          {!forgotPassword && (
            <LoginInput
              placeholderName="Digite novamente sua nova senha"
              type="password"
              onChangeAction={setNewPassword}
              onFocusAction={handleIsWrong}
            />
          )}
        </LoginFormInputsContainer>
        {isWrong && !forgotPassword && (
          <ErrorMessage>
            Assegure-se de que a suas senhas coincidem, possuem pelo
            <br /> menos oito dígitos e não contenham espaços vazios
          </ErrorMessage>
        )}
        {isWrong && forgotPassword && (
          <ErrorMessage>Email ou senha incorretos!</ErrorMessage>
        )}
        <LoginFormOptionsContainer>
          {forgotPassword && (
            <ForgetPasswordLink onClick={() => setShowModal(!showModal)}>
              Esqueceu sua senha ?
            </ForgetPasswordLink>
          )}
          <LoginButton type="submit">
            {forgotPassword ? 'Login' : 'Alterar senha'}
          </LoginButton>
        </LoginFormOptionsContainer>
      </LoginFormContainer>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <LoginContainer>
          <LoginTitle>Recuperação de senha</LoginTitle>
          <LoginFormContainer>
            <RecoverPasswordMessage>
              Verifique a caixa de entrada do email para prosseguir
            </RecoverPasswordMessage>
            <LoginInput
              placeholderName="Digite o email cadastrado"
              onChangeAction={setSendEmail}
              onFocusAction={handleIsWrong}
            />
            <LoginButton
              onClick={() => {
                ForgotPassword();
              }}
            >
              Enviar
            </LoginButton>
          </LoginFormContainer>
        </LoginContainer>
      </Modal>
    </>
  );
};

export default LoginForm;
