import React from 'react';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';

import {
  DeleteAccountButton,
  DeleteAccountButtonsContainer,
  DeleteAccountDescription,
  DeleteAccountModalContent,
  DeleteAccountTitle,
} from './DeleteAccount.elements';

interface IDeleteAccountProps {
  modalAction: () => void;
}

export const DeleteAccount: React.FC<IDeleteAccountProps> = ({
  modalAction,
}: IDeleteAccountProps) => {
  const { ...authInfo } = useAuth();

  const deleteUser = async () => {
    await api.delete(`/users/${authInfo.user.id}`);
    authInfo.signOut();
  };

  return (
    <DeleteAccountModalContent>
      <DeleteAccountTitle>
        Você tem certeza que deseja deletar sua conta ?
      </DeleteAccountTitle>
      <DeleteAccountDescription>
        Esta é uma ação irreversível. Tem certeza que deseja continuar?
      </DeleteAccountDescription>
      <DeleteAccountButtonsContainer>
        <DeleteAccountButton cancel={true} onClick={modalAction}>
          Não
        </DeleteAccountButton>
        <a href="http://www.sonhe.med.br/">
          <DeleteAccountButton
            onClick={() => {
              modalAction();
              deleteUser();
            }}
          >
            Sim
          </DeleteAccountButton>
        </a>
      </DeleteAccountButtonsContainer>
    </DeleteAccountModalContent>
  );
};

export default DeleteAccount;
