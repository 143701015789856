import styled from 'styled-components';
import {
  ModalButton,
  ModalContent,
  ModalTitle,
} from '../../../Profile/ModalContents/GlobalModalContentStyle';

export const AddParticipantModalContent = styled(ModalContent)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto;
  grid-row-gap: 4rem;
  grid-template-areas:
    'AddParticipantTitle'
    'AddParticipantsCurrentContainer'
    'AddParticipantSearchContainer'
    'AddParticipantsList'
    'AddParticipantContainer';
`;

export const AddParticipantsCurrentContainer = styled.div``;

export const AddParticipantsCurrentContainerTitle = styled.strong`
  color: ${props => props.theme.color.black};
  font: ${props => props.theme.font.featuredText};
`;

export const AddParticipantsCurrentList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 60rem;
  max-height: 25rem;
  overflow: auto;
`;

export const AddParticipantsCurrentItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.white};
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  cursor: pointer;
  transition: 0.5s;

  span {
    align-self: flex-start;
    font-size: 1.2rem;
    font-weight: 900;
    color: ${props => props.theme.color.red};
    transition: 0.1s;
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const AddParticipantsCurrentText = styled.p`
  color: ${props => props.theme.color.blue};
  font: ${props => props.theme.font.text};
  transition: 0.3s;
`;

export const AddParticipantTitle = styled(ModalTitle)`
  font: ${props => props.theme.font.mediumText};
  grid-area: AddParticipantTitle;
`;

export const AddParticipantSearchContainer = styled.p`
  grid-area: AddParticipantSearchContainer;
`;

export const AddParticipantButtonsContainer = styled.div`
  grid-area: AddParticipantContainer;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  grid-column-gap: 2rem;
`;

export const AddParticipantsList = styled.div`
  grid-area: AddParticipantsList;
  overflow: auto;
  max-height: 24rem;
`;

export const DoctorContainer = styled.div``;

export const AddParticipantButton = styled(ModalButton)``;

export const SearchParticipantContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 1rem;

  svg {
    height: 2.4rem;
    cursor: pointer;

    path {
      fill: ${props => props.theme.color.blue};
      fill-opacity: 1;
    }
  }
`;

export const SearchParticipant = styled.input`
  width: 100%;
  padding: 1rem 1.5rem 1rem 4rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`;

export const OrderDoctors = styled.select`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`;
