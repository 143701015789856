import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  width: 100%;
  max-height: 4rem;
  padding: 1rem;

  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }
`;

export const OptionTitle = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.blue};
  margin: 0;
  margin-left: 2rem;
`;

export const IconContainer = styled.div`
  width: 2rem;
`;
