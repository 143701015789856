import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface OptionContainerProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  differentAction?: boolean;
}

export const GridContainer = styled.section`
  display: grid;
  grid-row-gap: 6rem;
  grid-template-rows: 1rem 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'pageTitle'
    'profileContainer';
  max-width: 56%;
  margin: 0 auto;
  margin-top: 5rem;
`;

export const PageTitle = styled.h1`
  grid-area: pageTitle;
  font: ${prosp => prosp.theme.font.bigText};
  color: ${props => props.theme.color.blue};
`;

export const ProfileContainer = styled.div`
  grid-area: profileContainer;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  padding: 2.4rem;
  margin-bottom: 5rem;
  background-color: rgb(255, 255, 255, 0.8);

  display: grid;
  justify-items: center;
  grid-row-gap: 5rem;
  grid-template-rows: 0.5fr auto auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'profileImage'
    'fieldsListContainer'
    'optionsContainer';
`;

export const ProfileImageContainer = styled.div`
  grid-area: profileImage;
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
`;

export const ProfileImage = styled.img`
  width: 12.8rem;
  height: 12.8rem;
  object-fit: cover;
  border-radius: 50%;
`;

export const ProfileImageEditButton = styled.button`
  display: flex;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  svg {
    height: 2.4rem;
    cursor: pointer;

    path {
      fill: ${props => props.theme.color.white};
      fill-opacity: 1;
    }
  }
  padding: 1rem;
  position: absolute;
  border: none;
  border-radius: 50%;
  background-color: ${props => props.theme.color.blue};
`;

export const FieldsListContainer = styled.form`
  grid-area: fieldsListContainer;
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-row-gap: 2rem;
  justify-items: center;
  width: 100%;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 30rem;
`;

export const Label = styled.label`
  font: ${props => props.theme.font.normalText};
  color: #1f1f1f;
  margin-bottom: 1rem;
`;

export const Input = styled.input`
  font: ${props => props.theme.font.text};
  border: 0.5px solid #c9c9c9;
  color: rgb(31, 31, 31, 0.8);
  padding: 1.8rem 1rem;
  border-radius: 0.5rem;
`;

export const TextArea = styled.textarea`
  border: 0.5px solid #c9c9c9;
  min-height: 10rem;
  max-width: 100%;
  min-width: 100%;
  color: rgb(31, 31, 31, 0.8);
  padding: 1.8rem 1rem;
  border-radius: 0.5rem;
`;

export const OptionsContainer = styled.div`
  grid-area: optionsContainer;
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-row-gap: 2rem;
  width: 50%;
`;

export const OptionIconContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 2.4rem;
    cursor: pointer;
    path {
      fill: ${props => props.theme.color.blue};
      fill-opacity: 1;
    }
  }
`;

export const OptionText = styled.h3`
  font: ${props => props.theme.font.normalText};
  color: ${props => props.theme.color.blue};
  margin: 0;
  &:hover {
    transition: 0.2s;
    font: ${props => props.theme.font.featuredNormalText};
  }
`;

export const OptionContainer = styled.div<OptionContainerProps>`
  display: grid;
  grid-template-columns: 2.4rem 1fr;
  grid-column-gap: 1rem;
  align-items: center;
  cursor: pointer;
  ${OptionIconContainer} {
    svg {
      path {
        fill: ${props =>
          props.differentAction ? '#EB5757' : props.theme.color.blue};
      }
    }
  }
  ${OptionText} {
    color: ${props =>
      props.differentAction ? '#EB5757' : props.theme.color.blue};
  }
`;

export const SaveChangesButton = styled.button`
  margin-top: 2rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
  &:hover {
    transition: 0.2s;
    background-color: ${props => props.theme.color.hoverBrown};
  }
`;

export const NameAndSurnameContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 50%;
`;

export const FieldContainerNameAndSurname = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 16.3rem;
`;
