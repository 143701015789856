import styled from 'styled-components';

export const PageContainer = styled.section`
  height: calc(100% - 5rem);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font: ${props => props.theme.font.bigText};
  margin: 5rem 0 1.5rem 0;
`;

export const Text = styled.p`
  max-width: 30rem;
  text-align: center;
  font: ${props => props.theme.font.normalText};
`;
