import React, { createContext, useState, useCallback, useContext } from 'react';

export interface IFilterProps {
  status: string;
  deadlineOrder: string;
  searchText: string;
  isSonhe: string;
}

interface FilterContextProps {
  filters: IFilterProps;
  changeStatus(status: React.ChangeEvent<HTMLSelectElement>): void;
  changeOrder(deadlineOrder: React.ChangeEvent<HTMLSelectElement>): void;
  changeDoctorsList(isSonhe: React.ChangeEvent<HTMLSelectElement>): void;
  changeSearchText(searchText: React.ChangeEvent<HTMLInputElement>): void;
}

const FilterContext = createContext<FilterContextProps>(
  {} as FilterContextProps,
);

export const FilterProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<IFilterProps>(() => {
    return {
      searchText: '',
      deadlineOrder: '',
      status: '',
      isSonhe: '',
    } as IFilterProps;
  });

  const changeStatus = useCallback(
    (status: React.ChangeEvent<HTMLSelectElement>) => {
      setFilters(oldFilters => ({
        ...oldFilters,
        status: status.target.value,
      }));
    },
    [],
  );

  const changeOrder = useCallback(
    (deadlineOrder: React.ChangeEvent<HTMLSelectElement>) => {
      setFilters(oldFilters => ({
        ...oldFilters,
        deadlineOrder: deadlineOrder.target.value,
      }));
    },
    [],
  );

  const changeSearchText = useCallback(
    (searchText: React.ChangeEvent<HTMLInputElement>) => {
      setFilters(oldFilters => ({
        ...oldFilters,
        searchText: searchText.target.value,
      }));
    },
    [],
  );

  const changeDoctorsList = useCallback(
    (isSonhe: React.ChangeEvent<HTMLSelectElement>) => {
      console.log(isSonhe.target.value, 'aqui');
      setFilters(oldFilters => ({
        ...oldFilters,
        isSonhe: isSonhe.target.value,
      }));
    },
    [],
  );

  return (
    <FilterContext.Provider
      value={{
        filters,
        changeStatus,
        changeOrder,
        changeDoctorsList,
        changeSearchText,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = (): FilterContextProps => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return context;
};
