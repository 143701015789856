import React from 'react';

import { PageContainer, Title, Text } from './UnderConstruction.elements';

import { Gears } from '../../assets/icons';

const UnderConstruction: React.FC = () => {
  return (
    <PageContainer>
      <Gears />
      <Title>Em breve no nosso app</Title>
      <Text>
        Essa funcionalidade poderá ser acessada no aplicativo do grupo SOnHe, em
        breve disponível na App Store e Google Play.
      </Text>
    </PageContainer>
  );
};

export default UnderConstruction;
