import React from 'react';
import {
  PrivacyTermsButton,
  PrivacyTermsButtonsContainer,
  PrivacyTermsDescription,
  PrivacyTermsModalContent,
  PrivacyTermsTitle,
} from './PrivacyTerms.elements';

interface IPrivacyTermsProps {
  modalAction: () => void;
}

const PrivacyTerms: React.FC<IPrivacyTermsProps> = ({
  modalAction,
}: IPrivacyTermsProps) => {
  return (
    <PrivacyTermsModalContent>
      <PrivacyTermsTitle>Termos de Privacidade - SOnHe</PrivacyTermsTitle>
      <PrivacyTermsDescription>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Delectus sequi
        corrupti, inventore ex totam tenetur quos animi dignissimos, soluta,
        corporis iusto vero assumenda nisi culpa laudantium laborum maiores
        autem libero. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Assumenda pariatur quidem fuga exercitationem itaque eveniet. Beatae,
        corrupti! Nobis molestias magni tenetur harum.
        {'\n'}
        Est asperiores et officiis molestias repellat quibusdam itaque? Lorem
        ipsum dolor, sit amet consectetur adipisicing elit. Libero magni
        dignissimos aut dicta praesentium similique a ut ullam architecto
        aliquam dolorum alias laudantium, fuga aliquid saepe, earum qui
        doloribus cupiditate! Lorem ipsum dolor sit amet consectetur adipisicing
        elit.
        {'\n'}
        Inventore tempora nemo vero ea, asperiores fugit temporibus debitis
        iusto ratione, animi harum vitae deserunt natus corporis sit nisi nobis
        consectetur corrupti. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Molestias rem doloremque aperiam quibusdam, architecto omnis,
        reiciendis neque placeat, facilis debitis consequatur nostrum. Eligendi,
        nemo nisi facere alias repudiandae perspiciatis earum. Lorem ipsum dolor
        sit amet consectetur adipisicing elit. Quo, doloribus? Atque totam
        deserunt delectus iste adipisci unde ipsa eum aliquid, rerum
        exercitationem aspernatur reprehenderit porro doloribus, nesciunt
        consequatur, nihil repellat!
        {'\n'}
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet nulla
        iusto ipsam! Esse quasi odio tempore natus similique temporibus quos,
        placeat illo vitae nulla omnis aliquid incidunt at perspiciatis tenetur!
        Lorem ipsum dolor sit, amet consectetur adipisicing elit.
        {'\n'}
        Similique nobis, numquam illum iste deserunt praesentium eos nisi culpa
        eveniet? Quisquam voluptates consectetur vitae provident exercitationem
        quidem suscipit, impedit neque itaque? Lorem ipsum dolor sit amet
        consectetur, adipisicing elit. Placeat rem dignissimos voluptates eaque
        fugit! Debitis suscipit, esse cupiditate id nemo iure laudantium maxime
        consequuntur in soluta, doloribus harum magni sapiente.
        {'\n'}
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed pariatur
        iste, vel quaerat cupiditate tempore et deserunt perferendis consectetur
        quod necessitatibus beatae aliquid illum dicta, corporis voluptatum
        nesciunt quas quo.
        {'\n'}
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed pariatur
        iste, vel quaerat cupiditate tempore et deserunt perferendis consectetur
        quod necessitatibus beatae aliquid illum dicta, corporis voluptatum
        nesciunt quas quo.
        {'\n'}
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed pariatur
        iste, vel quaerat cupiditate tempore et deserunt perferendis consectetur
        quod necessitatibus beatae aliquid illum dicta, corporis voluptatum
        nesciunt quas quo.
      </PrivacyTermsDescription>
      <PrivacyTermsButtonsContainer>
        <PrivacyTermsButton onClick={modalAction}>
          Li e estou de acordo
        </PrivacyTermsButton>
      </PrivacyTermsButtonsContainer>
    </PrivacyTermsModalContent>
  );
};

export default PrivacyTerms;
