import React, { useEffect, useRef, useState } from 'react';
import { useSocket } from '../../hooks/socket';

import {
  Container,
  DropDownMenu,
  NotificationTitle,
  Wrapper,
} from './NotificationBox.elements';

import { Notification } from '../../assets/icons';
import NotificationItem from './NotificationItem';
import HomeDivisor from '../HomeComponents/HomeDivisor';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { countTimeSince } from '../../utils/countTimeSince';

const defaultPhoto =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7WkdZRcEzdm6UdF4r7MMXzAxXWBnyMCxMEQ&usqp=CAU';

export interface NotificationProps {
  id?: string;
  photo: string;
  title: string;
  type: string;
  deadline: string;
  description: string;
  discussionId: string;
}

const NotificationBox: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { socket } = useSocket();
  const { user } = useAuth();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [notifications, setNotifications] = useState<NotificationProps[]>(
    () => {
      return [] as NotificationProps[];
    },
  );

  const page = 0;
  const perPage = 10;

  const loadNotifications = async () => {
    const res = await api.get(
      `user-notifications/${user.id}/?perPage=${perPage}&page${page}`,
    );

    const data: NotificationProps[] = res.data.map((notification: any) => ({
      id: notification.id,
      photo: notification.image_address,
      title: notification.title,
      type: notification.notification_type,
      description: notification.content,
      deadline: notification.created_at,
      discussionId: notification.entity.entity_id,
    }));
    setNotifications(data);
  };

  const setNewIdNotification = (notificationId: string) => {
    if (notificationId != undefined) {
      localStorage.setItem(
        '@SOnHe:notification',
        JSON.stringify(notificationId),
      );
    }
  };

  const getNotificationIcon = (): SVGPathElement | null | undefined => {
    const notIcon = document.querySelector('#notification') as HTMLDivElement;
    if (notIcon != null && notIcon.querySelector('svg') != null) {
      const icon = notIcon.querySelector('svg');
      if (icon != null) {
        const pathSvg = icon.querySelector('path');
        return pathSvg;
      }
    } else {
      return null;
    }
  };

  const newNotification = () => {
    const notIcon = getNotificationIcon();

    if (notIcon != null && notIcon != undefined) {
      if (open == false) {
        notIcon.style.fill = '#EB5757';
      } else {
        notIcon.style.fill = 'white';
      }
    }
  };

  const checkNotification = () => {
    let id = localStorage.getItem('@SOnHe:notification');
    if (id != null) {
      id = JSON.parse(id);
      if (notifications[0]?.id != undefined) {
        if (id !== notifications[0]?.id) {
          newNotification();
        }
      }
    }
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    checkNotification();
  }, [notifications[0]?.id]);

  useEffect(() => {
    if (socket) {
      socket.on(`${user.id}`, (data: any) => {
        setNotifications([
          {
            id: data.id,
            photo: data.image_address,
            title: data.title,
            type: data.notification_type,
            description: data.content,
            deadline: data.created_at,
            discussionId: data.entity.entity_id,
          },
          ...notifications,
        ]);
        setNewIdNotification(data.id);
        newNotification();
        if (notifications.length >= perPage - 1) {
          loadNotifications();
        }
      });
    }
  }, [notifications, socket]);

  const handler = (event: { target: any }) => {
    const reference = menuRef.current;
    const notIcon = getNotificationIcon();
    if (reference && !reference.contains(event.target)) {
      setOpen(false);
      if (notIcon != null && notIcon != undefined) {
        notIcon.style.fill = 'white';
      }
    } else {
      const notificationIcon = document.getElementById('notification');
      if (notificationIcon != reference) {
        if (notIcon != null && notIcon != undefined && open == false) {
          notIcon.style.fill = 'white';
          if (notifications[0]?.id != undefined) {
            setNewIdNotification(notifications[0]?.id);
          }
        }

        setOpen(!open);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  });

  return (
    <>
      <Container ref={menuRef}>
        <Notification />
        {open && (
          <DropDownMenu>
            <Wrapper>
              <NotificationTitle> Notificações </NotificationTitle>
              {notifications?.map(
                ({
                  id,
                  photo,
                  title,
                  type,
                  description,
                  deadline,
                  discussionId,
                }) => (
                  <>
                    <NotificationItem
                      key={id}
                      id={id}
                      photo={
                        photo != '' && photo != undefined ? photo : defaultPhoto
                      }
                      title={title}
                      type={type}
                      description={description}
                      deadline={countTimeSince(deadline)}
                      discussionId={discussionId}
                    />
                    <HomeDivisor />
                  </>
                ),
              )}
            </Wrapper>
          </DropDownMenu>
        )}
      </Container>
    </>
  );
};

export default NotificationBox;
