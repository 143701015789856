import React from 'react';

import {
  DoctorContainer,
  DoctorFirstPart,
  DoctorMainTextContainer,
  DoctorSecondTextContainer,
  DoctorName,
  DoctorCRM,
  DoctorSpecialty,
  DoctorEmail,
  DoctorImageContainer,
  DoctorImage,
  LeftContainer,
  RightContainer,
} from './Doctor.elements';

type infos = {
  userId: string;
  doctorImage: string;
  doctorName: string;
  CRM: string;
  doctorSpecialty?: any;
  doctorEmail: string;
};

const Doctor: React.FC<infos> = ({
  userId,
  doctorImage,
  doctorName,
  doctorSpecialty,
  doctorEmail,
  CRM,
}) => {
  return (
    <>
      <DoctorContainer key={userId}>
        <LeftContainer>
          <DoctorFirstPart>
            <DoctorImageContainer>
              <DoctorImage DImage={doctorImage}></DoctorImage>
            </DoctorImageContainer>
            <DoctorMainTextContainer>
              <DoctorName>{doctorName}</DoctorName>
              <DoctorCRM>CRM: {CRM}</DoctorCRM>
              <br />
            </DoctorMainTextContainer>
          </DoctorFirstPart>
          <DoctorSecondTextContainer>
            <DoctorEmail>{doctorEmail}</DoctorEmail>
          </DoctorSecondTextContainer>
        </LeftContainer>
        <RightContainer>
          <DoctorSpecialty>{doctorSpecialty}</DoctorSpecialty>
        </RightContainer>
      </DoctorContainer>
    </>
  );
};
export default Doctor;
