export const countDeadline = (deadline: string): number => {
  const today = new Date();
  const discussionDeadline = new Date(deadline);
  if (today.getMonth() == 11 && today.getDate() > 25) {
    discussionDeadline.setFullYear(discussionDeadline.getFullYear() + 1);
  }
  const one_day = 1000 * 60 * 60 * 24;

  return Number(
    Math.ceil((discussionDeadline.getTime() - today.getTime()) / one_day),
  );
};
