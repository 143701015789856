import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface CaseProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  backgroundColor?: string;
  hoverColor?: string;
}

interface DoctorProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  photo: string;
}

export const Case = styled.div<CaseProps>`
  padding: 1.4rem;
  align-self: flex-start;
  display: flex;
  width: 100%;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.color.white};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${props =>
      props.hoverColor ? props.hoverColor : '#dddddd'};
  }

  transition: background-color 0.1s ease-in;
`;
export const CasePhoto = styled.div<DoctorProps>`
  height: 5rem;
  width: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  min-width: 5rem;
  min-height: 5rem;
  border-radius: 50%;

  background-image: url(${props => props.photo});
  background-size: 5rem;
  background-image: no-repeat;
  background-size: cover;
`;

export const CaseInfoContainer = styled.div`
  margin-left: 1.5rem;
`;
export const CaseDetailsContainer = styled.div`
  display: flex;
  margin-top: 0.2rem;
  align-items: center;
`;

export const CaseTitle = styled.p`
  font: ${props => props.theme.font.featuredNormalText};
  color: ${props => props.theme.color.black};
`;

export const CaseDeadline = styled.p`
  font: normal normal bold 1.3rem 'Roboto', sans-serif;
  color: ${props => props.theme.color.black};
`;

export const CaseStatus = styled.p`
  margin-left: 1rem;
  font: normal normal bold 1.3rem 'Roboto', sans-serif;
  color: ${props => props.theme.color.black};
`;

export const DeadlineText = styled.p`
  margin-right: 0.5rem;
  font: normal normal normal 1.3rem 'Roboto', sans-serif;
  color: ${props => props.theme.color.black};
`;
