import React, { ChangeEvent } from 'react';

import { LoginInputField } from './LoginInput.elements';

interface LoginInputProps {
  placeholderName: string;
  type?: string;
  onChangeAction: (value: string) => void;
  onFocusAction: (value: string) => void;
}

const LoginInput: React.FC<LoginInputProps> = ({
  placeholderName,
  type,
  onChangeAction,
  onFocusAction,
}: LoginInputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeAction(e.target.value);
  };
  return (
    <LoginInputField
      placeholder={placeholderName}
      type={type}
      onChange={handleChange}
      onFocus={() => onFocusAction('')}
    />
  );
};

export default LoginInput;
