import React, { useState } from 'react';
import { Container, DropDownMenu, ActionContainer } from './CaseMenu.elements';

import {
  More,
  AddPerson,
  Paste,
  Edit,
  Delete,
  Return,
} from '../../../assets/icons';
import MenuOptions from '../MenuOptions';

interface CaseMenuProps {
  openModal: () => void;
  editStatus: () => void;
  discussionId: string;
  isOwner?: boolean;
}

const CaseMenu: React.FC<CaseMenuProps> = ({
  openModal,
  editStatus,
  discussionId,
  isOwner,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Container onClick={() => setOpen(!open)}>
        <More />
        {open && isOwner && (
          <DropDownMenu>
            <MenuOptions
              title="Editar discussão"
              icon={Paste}
              redirectTo={`/editar-discussao/${discussionId}/${isOwner}`}
            />
            <ActionContainer onClick={editStatus}>
              <MenuOptions title="Editar status" icon={Edit} />
            </ActionContainer>
            <MenuOptions
              title="Adicionar participante"
              icon={AddPerson}
              redirectTo={'/em-construção'}
            />
            <ActionContainer onClick={openModal}>
              <MenuOptions title="Apagar" icon={Delete} />
            </ActionContainer>
          </DropDownMenu>
        )}
      </Container>
    </>
  );
};

export default CaseMenu;
