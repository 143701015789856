import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface DoctorProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  photo: string;
  zIndex: number;
}

export const Container = styled.div`
  margin: 0 auto;
  max-width: 56%;
  margin-top: 5rem;

  padding-top: 2.5rem;
  border-radius: 0.5rem;
  position: relative;
  z-index: -20;
  background-color: ${props => props.theme.color.white};
`;

export const WrapperHeaderInfos = styled.div`
  padding: 2rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font: ${props => props.theme.font.bigText};
  color: ${props => props.theme.color.black};
  max-width: 60rem;
  user-select: text;
`;

export const CaseDetailsContainer = styled.div`
  display: flex;
  margin-top: 2.5rem;
  align-items: center;
`;

export const DeadlineText = styled.p`
  margin-left: 0.5rem;
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
`;

export const StatusText = styled.p`
  margin-left: 3rem;
  margin-right: 0.5rem;
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
`;

export const SelectStatus = styled.select`
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  background-color: #fff;
  color: #131313;
`;

export const CaseDeadline = styled.p`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
`;

export const CaseStatus = styled.p`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
`;

export const CreatedByText = styled.p`
  margin-right: 0.5rem;
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
`;
export const Creator = styled.p`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
`;

export const DoctorsTitle = styled.p`
  font: ${props => props.theme.font.featuredNormalText};
  color: ${props => props.theme.color.black};
  margin-top: 2.5rem;
`;

export const Doctor = styled.div<DoctorProps>`
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  margin-top: 0.6rem;
  margin-left: -1rem;

  background-image: url(${props => props.photo});
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -${props => props.zIndex};
`;

export const DoctorsContainer = styled.div`
  display: flex;
  margin-left: 1rem;
`;

export const MoreDoctors = styled.div`
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  margin-top: 0.6rem;
  margin-left: -1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${props => props.theme.color.mainBrown};
  background-repeat: no-repeat;
  z-index: -10;
`;

export const MoreDoctorsText = styled.text`
  font-size: 15px;
  color: #ffffff;
`;

export const CaseBody = styled.div`
  width: 100%;
  max-height: 120rem;
  overflow: auto;
  padding: 4rem 1rem 4rem 2rem;
  user-select: text;
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};

  p {
    line-height: 2rem;
  }

  p > img {
    max-width: 80%;
    text-align: center !important;
  }

  ol,
  ul {
    padding-left: 4rem;
  }
  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
`;

export const CommentsContainer = styled.div`
  margin: 0 auto;

  max-width: 56%;
  margin-top: 5rem;
`;

export const CommentsWrapper = styled.div`
  max-height: 40rem;
  overflow: auto;
`;

export const CommentsTitle = styled.p`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
  margin-bottom: 1.7rem;
`;

export const AddComment = styled.textarea`
  padding: 2rem;
  padding-bottom: 6rem;
  max-width: 100%;
  min-width: 100%;
  border: 0.5px solid #c9c9c9;
  background-color: ${props => props.theme.color.white};
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  margin-top: 2rem;

  font: ${props => props.theme.font.text};
`;

export const CommentsBottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const CreateCommentFileContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const OverlayCommentFilePreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.2s ease-in;
  background-color: rgb(201, 201, 201, 0.8);

  &:hover {
    opacity: 1;
  }

  span {
    font: ${props => props.theme.font.bigText};
    color: ${props => props.theme.color.red};
  }
`;

export const CreateCommentFilePreview = styled.img`
  max-width: 10rem;
  max-height: 5rem;

  transition: 0.1s ease-in;
`;

export const SelectFileButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-content: center;
`;

export const SelectFileButton = styled.button`
  position: relative;

  display: grid;
  align-items: center;
  grid-template-columns: auto auto;

  padding: 1rem 1.5rem;
  border: 0.1rem solid #c9c9c9;
  border-radius: 0.5rem;
  background-color: #fafafa;
  margin-right: 3rem;

  font: ${props => props.theme.font.normalText};
  color: ${props => props.theme.color.mainBrown};

  transition: 0.1s ease-in;

  &:hover {
    opacity: 0.7;
  }

  input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export const SelectFileIconContainer = styled.div`
  cursor: pointer;
  svg {
    height: 2.4rem;
    cursor: pointer;

    path {
      fill: ${props => props.theme.color.mainBrown};
      fill-opacity: 1;
    }
  }
`;

export const SendButton = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;

  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};

  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }

  transition: background-color 0.1s ease-in;
`;

export const DeleteContainer = styled.div`
  padding: 2.5rem;
  background-color: ${props => props.theme.color.white};
  border-radius: 0.5rem;
`;

export const DeleteWarningTitle = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
`;

export const DeleteWarningText = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.blue};
  margin-top: 2.5rem;
`;

export const ModalButtonsContainer = styled.div`
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const DeleteButton = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;

  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};

  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }
`;
export const CancelButton = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  margin-right: 2rem;
  border: 1px solid ${props => props.theme.color.mainBrown};

  font: ${props => props.theme.font.featuredNormalText};
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.mainBrown};

  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
    color: ${props => props.theme.color.white};
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18rem;
`;
