import styled from 'styled-components';
import {
  ModalButton,
  ModalContent,
  ModalTitle,
} from '../GlobalModalContentStyle';

export const FeedbackForDevelopersModalContent = styled(ModalContent)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-row-gap: 4rem;
  grid-template-areas:
    'feedbackTitle'
    'feedbackInputsContainer'
    'feedbackButtonsContainer';
  min-width: 50rem;
`;

export const FeedbackForDevelopersTitle = styled(ModalTitle)`
  font: ${props => props.theme.font.mediumText};
  grid-area: feedbackTitle;
`;

export const FeedbackForDevelopersInputsContainer = styled.div`
  grid-area: feedbackInputsContainer;

  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  grid-template-rows: auto auto;
  grid-template-areas:
    'feedbackSelect'
    'feedbackTextarea';
`;

export const FeedbackForDevelopersSelect = styled.select`
  grid-area: feedbackSelect;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  max-width: 40%;

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`;

export const FeedbackForDevelopersTextArea = styled.textarea`
  grid-area: feedbackTextarea;
  border: 0.5px solid #c9c9c9;
  min-height: 16rem;
  width: 50rem;
  max-width: 50rem;
  color: rgb(31, 31, 31, 0.8);
  padding: 1.8rem 1rem;
  border-radius: 0.5rem;
`;

export const FeedbackForDevelopersButtonsContainer = styled.div`
  grid-area: feedbackButtonsContainer;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  grid-column-gap: 2rem;
`;

export const FeedbackForDevelopersButton = styled(ModalButton)``;
