import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface DoctorProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  photo: string;
  zIndex: number;
}

export const Container = styled.div`
  margin: 0 auto;
  max-width: 56%;
  margin-top: 5rem;
`;

export const CardsContainer = styled.div`
  margin: 0 auto;
  max-width: 56%;
  margin-top: 2.5rem;
  grid-gap: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 18rem 4rem;
  grid-template-areas: 'card1 card2 options options';
`;

export const OptionsContainer = styled.div`
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  grid-area: options;
  background-color: ${props => props.theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font: ${props => props.theme.font.bigText};
  color: ${props => props.theme.color.blue};
  margin: 0;
`;
export const SubtitleContainer = styled.div`
  display: flex;
  margin: 0;
  margin-top: 1.5rem;
`;
export const PhoneContainer = styled.div`
  display: flex;
  margin: 0;
  margin-top: 0.5rem;
`;
export const SubtitleName = styled.p`
  font: ${props => props.theme.font.normalText};
  color: ${props => props.theme.color.blue};
  margin: 0;
  margin-right: 0.5rem;
`;
export const Subtitle = styled.p`
  font: ${props => props.theme.font.featuredNormalText};
  color: ${props => props.theme.color.blue};
`;

export const CIDCodeContainer = styled.p`
  font: ${props => props.theme.font.featuredNormalText};
  color: ${props => props.theme.color.blue};
  margin: 0;
`;

export const DoctorsTitle = styled.p`
  font: ${props => props.theme.font.featuredNormalText};
  color: ${props => props.theme.color.black};
  margin: 0;
  margin-top: 1rem;
`;

export const Doctor = styled.div<DoctorProps>`
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  margin: 0;
  margin-top: 0.6rem;
  margin-left: -1rem;

  background-image: url(${props => props.photo});
  background-image: no-repeat;
  background-size: cover;
  z-index: -${props => props.zIndex};
`;

export const DoctorsContainer = styled.div`
  display: flex;
  margin-left: 1rem;
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: ${props => props.theme.color.white};
`;
export const ModalTitle = styled.p`
  font: ${props => props.theme.font.bigTitle};
  color: ${props => props.theme.color.blue};
  margin-top: 1.9rem;
  margin-bottom: 1rem;
`;

export const RadioButton = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1.6rem;
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
  input[type='radio']:after {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    position: relative;
    background-color: transparent;
    content: '';
    display: flex;
    visibility: visible;
    border: 0.3rem solid ${props => props.theme.color.blue};
    border-color: ${props => props.theme.color.blue};
  }

  input[type='radio']:checked:after {
    width: 0.6rem;
    height: 0.6rem;
    top: 0.4rem;
    left: 0.4rem;
    border-radius: 0.6rem;
    position: relative;
    background-color: ${props => props.theme.color.blue};
    content: '';
    display: flex;
    visibility: visible;
  }
`;

export const RadioButtonLabel = styled.label`
  margin-left: 1rem;
`;

export const LineRadioButton = styled.div`
  margin-bottom: 1.6rem;
  padding-left: 3rem;
  display: flex;
`;

export const RadioButtonInput = styled.input`
  height: 2rem;
  width: 2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const CancelButton = styled.button`
  width: 19rem;
  height: 5rem;
  color: ${props => props.theme.color.red};
  background-color: ${props => props.theme.color.white};
  font: ${props => props.theme.font.mediumText};
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${props => props.theme.color.red};
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.color.red};
    color: ${props => props.theme.color.white};
    transition: background-color 0.1s ease-in;
  }
`;

export const CancelButtonContainer = styled.div`
  margin-top: 2.6rem;
  margin-left: 8.1rem;
  margin-right: 2.7rem;
  margin-bottom: 3.2rem;
`;

export const ConfirmButtonContainer = styled.div`
  margin-top: 2.6rem;
  margin-left: 2.7rem;
  margin-right: 8.1rem;
  margin-bottom: 3.2rem;
`;

export const ConfirmButton = styled.button`
  width: 19rem;
  height: 5rem;
  color: ${props => props.theme.color.green};
  background-color: ${props => props.theme.color.white};
  font: ${props => props.theme.font.mediumText};
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${props => props.theme.color.green};
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.color.green};
    color: ${props => props.theme.color.white};
    transition: background-color 0.1s ease-in;
  }
`;

export const DeleteContainer = styled.div`
  padding: 2.5rem;
  background-color: ${props => props.theme.color.white};
  border-radius: 0.5rem;
`;

export const DeleteWarningTitle = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
`;

export const DeleteWarningText = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.blue};
  margin-top: 2.5rem;
`;

export const ModalButtonsContainer = styled.div`
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const DeleteModalButton = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }
`;
export const CancelModalButton = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  margin-right: 2rem;
  border: 1px solid ${props => props.theme.color.mainBrown};
  font: ${props => props.theme.font.featuredNormalText};
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.mainBrown};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
    color: ${props => props.theme.color.white};
  }
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 2rem;
  width: 100%;
`;

export const InfosForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const InfosInput = styled.input`
  margin-top: 1rem;
  font: ${props => props.theme.font.text};
  border: 0.5px solid #c9c9c9;
  color: rgb(31, 31, 31, 0.8);
  padding: 1.8rem 1rem;
  border-radius: 0.5rem;
`;

export const InfosLabel = styled.label`
  font: ${props => props.theme.font.normalText};
  color: #1f1f1f;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18rem;
`;
