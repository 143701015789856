import styled from 'styled-components';

export const NavContainer = styled.nav`
  height: 5rem;
  width: 100vw;
  background-color: ${props => props.theme.color.mainBrown};
  position: relative;
  display: flex;
  justify-content: center;
`;

export const MainContentContainer = styled.header`
  height: 100%;
  width: 56%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListOfItems = styled.div`
  height: 100%;
  width: 70rem;
  display: flex;
  align-items: center;
`;

export const ListOfIcons = styled.div`
  height: 100%;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 0.1s ease-in;
  margin-left: 4rem;

  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }

  svg {
    height: 3rem;
    cursor: pointer;

    path {
      fill: white;
      fill-opacity: 1;
    }
  }
`;

export const ProfileImageContainer = styled.div`
  max-width: 4rem;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
`;

export const ProfileImage = styled.img`
  object-fit: cover;
  max-width: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  border-radius: 50%;
`;
