import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface DoctorProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  DImage?: string;
}

export const DoctorFirstPart = styled.div`
  display: flex;
`;

export const LeftContainer = styled.div`
  position: relative;
  align-self: flex-start;
  background-color: none;
  text-decoration: none;
  flex-direction: column;
  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }
  transition: background-color 0.1s ease-in;
`;

export const RightContainer = styled.div`
  position: relative;
  align-self: flex-start;
  background-color: none;
  text-decoration: none;
  flex-direction: column;
  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }
  transition: background-color 0.1s ease-in;
`;

export const DoctorContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 1.5rem;
`;

export const DoctorMainTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 0.7rem;
`;

export const DoctorSecondTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const DoctorName = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.black};
  margin-bottom: 0.8rem;
`;

export const DoctorCRM = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
`;

export const DoctorSpecialty = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
  margin-bottom: 0.8rem;
`;

export const DoctorEmail = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
`;

export const DoctorImage = styled.div<DoctorProps>`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-image: url(${props => props.DImage});
  background-image: no-repeat;
  background-size: cover;
`;

export const DoctorImageContainer = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
`;
