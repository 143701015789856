import React, { useCallback, useEffect, Fragment } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactRouteProps,
  Redirect,
} from 'react-router-dom';
import { Navbar } from '../components';
import { useAuth } from '../hooks/auth';
import api from '../services/api';

interface RouteProps extends ReactRouteProps {
  isPrivate?: boolean;
  component: React.FC;
}

const PrivateRoute: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const token = localStorage.getItem('@SOnHe:token');
  const { signOut } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        api.post('/verify-token').catch(() => {
          signOut();
        });
        return isPrivate === !!token ? (
          <>
            <Navbar />
            {Component}
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
