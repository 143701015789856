import React, { createRef, useState, useEffect } from 'react';
import { Search } from '../../../assets/icons';
import api from '../../../services/api';

import {
  FilterContainer,
  IconContainer,
  OrderDiscussion,
  SearchDiscussion,
  SearchDiscussionContainer,
  SelectsContainer,
  StatusDiscussion,
} from './Filter.elements';
import { useFilter } from './FilterContext';

const Filter: React.FC = () => {
  const [status, setStatus] = useState([]);
  const { changeOrder, changeSearchText, changeStatus } = useFilter();
  const searchInput = createRef<HTMLInputElement>();

  const LoadStatus = async () => {
    const res = await api.get(`/discussions-status`);
    if (res && res.data) {
      setStatus(res.data);
    }
  };

  useEffect(() => {
    LoadStatus();
  }, []);

  return (
    <FilterContainer>
      <SearchDiscussionContainer>
        <IconContainer>
          <Search />
        </IconContainer>
        <SearchDiscussion
          placeholder="Buscar discussão"
          ref={searchInput}
          onChange={changeSearchText}
        />
      </SearchDiscussionContainer>
      <SelectsContainer>
        <OrderDiscussion onChange={changeOrder}>
          <option value="" selected>
            Ordenar prazo
          </option>
          <option value="DESC">Maior</option>
          <option value="ASC">Menor</option>
        </OrderDiscussion>
        <StatusDiscussion onChange={changeStatus}>
          <option value="" selected>
            Qualquer status
          </option>
          {status.map(({ name, id }, index) => (
            <option key={index} value={id}>
              {name}
            </option>
          ))}
        </StatusDiscussion>
      </SelectsContainer>
    </FilterContainer>
  );
};

export default Filter;
