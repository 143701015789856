import styled from 'styled-components';

export const GridContainer = styled.div`
  display: grid;
  grid-row-gap: 5rem;
  grid-template-rows: 4rem 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'filterContainer'
    'discussionContainer';
  max-width: 56%;
  margin: 0 auto;
  margin-top: 5rem;
`;

export const FilterSection = styled.div`
  grid-area: filterContainer;
`;

export const DiscussionsContainerButton = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;

  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
`;

export const DiscussionsContainerTitle = styled.h2`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
`;

export const DiscussionsContainerHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.5rem;
`;

export const DiscussionsContainerHeader = styled.div``;

export const DiscussionsContainer = styled.div`
  grid-area: discussionContainer;
`;

export const DiscussionsList = styled.div`
  max-height: 50vh;
  margin-bottom: 40px;
  overflow: auto;
`;

export const Divisor = styled.div`
  height: 0.1rem;
  width: 100%;
  background-color: #d1d3d4;
`;
