import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
`;
export const ActionContainer = styled.div``;

export const DropDownMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 26rem;
  overflow: hidden;

  background-color: ${props => props.theme.color.white};
  border: 1px solid #adadad;
  border-radius: 0.5rem;
`;
