/* eslint no-restricted-globals:0 */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { mask, unMask } from 'remask';
import Loader, { ThreeDots } from 'react-loader-spinner';

import api from '../../services/api';

import {
  Title,
  Container,
  SubtitleContainer,
  PhoneContainer,
  SubtitleName,
  Subtitle,
  DoctorsTitle,
  Doctor,
  CardsContainer,
  OptionsContainer,
  DoctorsContainer,
  ModalContainer,
  ModalTitle,
  RadioButton,
  LineRadioButton,
  RadioButtonInput,
  RadioButtonLabel,
  ButtonContainer,
  CancelButtonContainer,
  CancelButton,
  ConfirmButtonContainer,
  ConfirmButton,
  DeleteContainer,
  DeleteWarningTitle,
  DeleteWarningText,
  ModalButtonsContainer,
  CancelModalButton,
  DeleteModalButton,
  InfosContainer,
  InfosForm,
  InfosInput,
  InfosLabel,
  CIDCodeContainer,
  LoaderContainer,
} from './Patient.elements';

import { Assignment, Create, Delete } from '../../assets/icons';

import {
  HomeCard,
  HomeDivisor,
  PatientOptions,
  Divisor,
  Modal,
} from '../../components';
import theme from '../../assets/styles/theme';
import { useAuth } from '../../hooks/auth';

interface PatientProps {
  id: string;
  name: string;
  status_id: number;
  status: string;
  number: string;
  CID: string;
  doctorsPhoto: Array<string>;
}

interface ParamTypes {
  patientUserId: string;
}

const Patient: React.FC = () => {
  const { user } = useAuth();
  const { patientUserId } = useParams<ParamTypes>();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfosModal, setShowInfosModal] = useState(false);
  const [patient, setPatient] = useState<PatientProps>(() => {
    return {} as PatientProps;
  });

  const [allStatus, setAllStatus] = useState<any[]>([]);
  const [status, setStatus] = useState({});
  const [name, setName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [CID, setCID] = useState('');
  const [LoadingPatient, setLoadingPatient] = useState(false);

  const loadPatient = async () => {
    console.log(patientUserId);
    const res = await api.get(`/patients-users/${patientUserId}`);
    if (res && res.data) {
      setPatient({
        id: res.data.patient.id,
        name: res.data.patient.name,
        status: res.data.patient.status.name,
        status_id: res.data.patient.status.id,
        number: res.data.patient.phone,
        CID: res.data.patient.CID,
        doctorsPhoto: [
          res.data?.doctor?.photo_address,
          res.data?.forwarder?.photo_address,
        ],
      });
      setName(res.data.patient.name);
      setTelephone(res.data.patient.number);
      setStatus(res.data.patient.status.id);
      setCID(res.data.patient.CID);
      setLoadingPatient(true);
    }
  };

  const loadAllStatus = async () => {
    const res = await api.get(`/patients-status`);
    if (res && res.data) {
      setAllStatus(res.data);
    }
  };

  const deletePatient = async () => {
    await api.delete(`/patients/${patient.id}`);
  };

  const updateStatus = async () => {
    await api.put(`/patients/${patient.id}`, {
      status_id: status,
    });
    loadPatient();
  };

  const updateInfos = async () => {
    await api.put(`/patients/${patient.id}`, {
      name: name,
      phone: telephone,
      CID: CID,
    });
    loadPatient();
  };

  useEffect(() => {
    loadAllStatus();
    loadPatient();
  }, []);

  const openOrCloseModal = () => {
    setShowModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  const openOrCloseDeleteModal = () => {
    setShowDeleteModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  const openOrCloseInfosModal = () => {
    setShowInfosModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const maskTelephone = (e: any) => {
    setTelephone(
      mask(unMask(e.target.value), [
        '9999-9999',
        '99999-9999',
        '(99) 9999-9999',
        '(99) 99999-9999',
      ]),
    );
  };

  return (
    <>
      {LoadingPatient ? (
        <>
          <Container>
            <Title>{patient.name}</Title>
            <SubtitleContainer>
              <SubtitleName>STATUS</SubtitleName>
              <Subtitle>{patient.status}</Subtitle>
            </SubtitleContainer>
            <PhoneContainer>
              <SubtitleName>TELEFONE</SubtitleName>
              <Subtitle>{patient.number}</Subtitle>
            </PhoneContainer>
            <PhoneContainer>
              <SubtitleName>CID</SubtitleName>
              <CIDCodeContainer>
                {patient.CID ? patient.CID : 'Não informado'}
              </CIDCodeContainer>
            </PhoneContainer>
            <DoctorsTitle> Médicos </DoctorsTitle>
            <DoctorsContainer>
              {patient?.doctorsPhoto?.map((photo_address, index) => (
                <Doctor
                  key={index}
                  zIndex={index}
                  photo={
                    photo_address !== null &&
                    photo_address !== '' &&
                    photo_address !== undefined
                      ? photo_address
                      : theme.defaultImage
                  }
                />
              ))}
            </DoctorsContainer>
          </Container>
          <CardsContainer>
            <HomeCard
              title="Feed do paciente"
              subtitle="Linha do tempo com informações sobre o estado do paciente."
              redirectTo={'/em-construção'}
              card3
            />
            <HomeCard
              title="Abrir o caso do paciente para discussão"
              subtitle="Abra uma discussão e adicione médicos da sua escolha para participar."
              redirectTo={`/criar-discussao/${patientUserId}`}
              card2
            />
            <OptionsContainer>
              <PatientOptions
                openModal={openOrCloseModal}
                title="Atualizar Status"
                icon={Assignment}
              />
              <Divisor />
              <PatientOptions
                openModal={openOrCloseInfosModal}
                title="Atualizar informações do paciente"
                icon={Create}
              />
              <Divisor />
              <PatientOptions
                openModal={openOrCloseDeleteModal}
                title="Deletar"
                icon={Delete}
              />
            </OptionsContainer>
          </CardsContainer>
        </>
      ) : (
        <LoaderContainer>
          <ThreeDots color={theme.color.mainBrown} height={100} width={100} />
        </LoaderContainer>
      )}

      {/* EditModal */}
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <ModalContainer>
          <ModalTitle>Selecione o novo status</ModalTitle>
          <RadioButton>
            {allStatus?.map(({ name, id }) => (
              <LineRadioButton>
                <RadioButtonInput
                  type="radio"
                  checked={status === id}
                  value={id}
                  onChange={e => {
                    setStatus(e.target.value);
                  }}
                />
                <RadioButtonLabel>{name}</RadioButtonLabel>
              </LineRadioButton>
            ))}
          </RadioButton>
          <Divisor />
          <ButtonContainer>
            <CancelButtonContainer>
              <CancelButton onClick={openOrCloseModal}>Cancelar</CancelButton>
            </CancelButtonContainer>
            <ConfirmButtonContainer>
              <ConfirmButton
                onClick={() => {
                  openOrCloseModal();
                  updateStatus();
                }}
              >
                Confirmar
              </ConfirmButton>
            </ConfirmButtonContainer>
          </ButtonContainer>
        </ModalContainer>
      </Modal>

      <Modal showModal={showDeleteModal} setShowModal={setShowDeleteModal}>
        <DeleteContainer>
          <DeleteWarningTitle>
            Você tem certeza que deseja apagar?
          </DeleteWarningTitle>
          <DeleteWarningText>
            {' '}
            Esta é uma ação irreversível. Tem certeza que deseja continuar?{' '}
          </DeleteWarningText>
          <ModalButtonsContainer>
            <CancelModalButton onClick={openOrCloseDeleteModal}>
              {' '}
              Cancelar{' '}
            </CancelModalButton>
            <DeleteModalButton
              onClick={deletePatient}
              as={Link}
              to={'/'}
              style={{ textDecoration: 'none' }}
            >
              {' '}
              Apagar{' '}
            </DeleteModalButton>
          </ModalButtonsContainer>
        </DeleteContainer>
      </Modal>

      <Modal showModal={showInfosModal} setShowModal={setShowInfosModal}>
        <ModalContainer>
          <ModalTitle>Atualizar informações do paciente</ModalTitle>
          <InfosContainer>
            <InfosForm>
              <InfosLabel htmlFor="name">Nome :</InfosLabel>
              <InfosInput
                id="name"
                type="text"
                defaultValue={patient.name}
                onChange={e => {
                  if (e.target.value != '') {
                    setName(e.target.value);
                  }
                }}
              ></InfosInput>
            </InfosForm>
          </InfosContainer>
          <InfosContainer>
            <InfosForm>
              <InfosLabel htmlFor="telephone">Telefone :</InfosLabel>
              <InfosInput
                id="telephone"
                type="text"
                defaultValue={patient.number}
                onChange={maskTelephone}
              ></InfosInput>
            </InfosForm>
          </InfosContainer>
          <InfosContainer>
            <InfosForm>
              <InfosLabel htmlFor="CID">CID :</InfosLabel>
              <InfosInput
                id="CID"
                type="text"
                defaultValue={patient.CID}
                onChange={e => {
                  if (e.target.value != '') {
                    setCID(e.target.value);
                  }
                }}
              ></InfosInput>
            </InfosForm>
          </InfosContainer>
          <Divisor />
          <ButtonContainer>
            <CancelButtonContainer>
              <CancelButton onClick={openOrCloseInfosModal}>
                Cancelar
              </CancelButton>
            </CancelButtonContainer>
            <ConfirmButtonContainer>
              <ConfirmButton
                onClick={() => {
                  openOrCloseInfosModal();
                  updateInfos();
                }}
              >
                Confirmar
              </ConfirmButton>
            </ConfirmButtonContainer>
          </ButtonContainer>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default Patient;
