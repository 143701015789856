import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import Loader, { ThreeDots } from 'react-loader-spinner';

import {
  HeaderContainer,
  Title,
  Container,
  DoctorsTitle,
  Doctor,
  DoctorsContainer,
  CaseDetailsContainer,
  DeadlineText,
  CaseDeadline,
  CaseStatus,
  StatusText,
  SelectStatus,
  CreatedByText,
  Creator,
  CaseBody,
  CommentsContainer,
  CommentsTitle,
  AddComment,
  ButtonsContainer,
  SendButton,
  DeleteContainer,
  DeleteWarningTitle,
  DeleteWarningText,
  ModalButtonsContainer,
  DeleteButton,
  CancelButton,
  CommentsWrapper,
  WrapperHeaderInfos,
  LoaderContainer,
  SelectFileButton,
  SelectFileIconContainer,
  SelectFileButtonContainer,
  CommentsBottomContainer,
  CreateCommentFilePreview,
  CreateCommentFileContainer,
  OverlayCommentFilePreview,
  MoreDoctors,
  MoreDoctorsText,
} from './SpecificCase.elements';

import { HomeDivisor, Comment, Modal } from '../../components';
import CaseMenu from './CaseMenu';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { countDeadline } from '../../utils/countDays';
import { dayAndMonth, hoursAndMinutes } from '../../utils/dateFunctions';
import theme from '../../assets/styles/theme';
import { Gallery, Pdf } from '../../assets/icons';

interface ParamTypes {
  discussionId: string;
}

interface commentType {
  content: string;
  image?: any;
  previewImage?: string;
  pdf?: any;
  previewPdf?: string;
}

const SpecificCase: React.FC = () => {
  const { discussionId } = useParams<ParamTypes>();
  const history = useHistory();
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [commentInput, setCommentInput] = useState<commentType>({
    content: '',
    image: '' as any,
    previewImage: '',
    pdf: '' as any,
    previewPdf: '',
  });
  const [editStatus, setEditStatus] = useState(false);
  const [ownerId, setOwnerId] = useState('');
  const [allStatus, setAllStatus] = useState(() => {
    return [] as any[];
  });
  const [discussion, setDiscussion] = useState<any>();
  const [LoadingDiscussion, setLoadingDiscussion] = useState(false);
  const [discussionUsers, setDiscussionUsers] = useState<any>([]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    updateDiscussionStatus(e.target.value);
    setEditStatus(false);
  };

  const openOrCloseModal = () => {
    setShowModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  const loadDiscussion = async () => {
    const res = await api.get(`discussions/${discussionId}`);

    res.data?.discussion_users?.forEach((ds: any) => {
      if (ds.isOwner) {
        setOwnerId(ds.user.id);
      }
    });

    const aux = res.data.discussion_users;
    if (aux.length > 9) {
      setDiscussionUsers(aux.slice(0, 9));
    } else {
      setDiscussionUsers(aux);
    }
    setDiscussion(res.data);
    setLoadingDiscussion(true);
  };

  const updateDiscussionStatus = async (statusId: string) => {
    await api.put(`discussions/${discussionId}`, {
      status_id: statusId,
    });
    loadDiscussion();
  };

  const loadStatus = async () => {
    const res = await api('discussions-status');
    setAllStatus(res.data);
  };

  const createComment = async () => {
    if (
      commentInput.content != '' ||
      commentInput.image != '' ||
      commentInput.pdf != ''
    ) {
      let res = await api.post('comments', {
        content: commentInput.content,
        discussion_id: discussionId,
        user_id: user.id,
      });

      if (commentInput.image != '') {
        const data = new FormData();
        data.append('files', commentInput.image);

        res = await api.post(`/files/comments/${res.data.id}`, data);
      }

      if (commentInput.pdf != '') {
        const data = new FormData();
        data.append('files', commentInput.pdf);

        res = await api.post(`/files/comments/${res.data.id}`, data);
      }

      setDiscussion({
        ...discussion,
        discussion_comments: [...discussion.discussion_comments, res.data],
      });

      setCommentInput({
        content: '',
        image: '',
        previewImage: '',
        pdf: '',
        previewPdf: '',
      });
      loadDiscussion();
    }
  };

  const deleteDiscussion = async () => {
    await api.delete(`discussions/${discussionId}`);

    history.push('/home');
  };

  const handleFileComment = async (
    e: ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    if (e.target.files) {
      const reader = new FileReader();
      const target = e.target.files[0];
      reader.readAsDataURL(e.target.files[0]);
      if (type == 'image') {
        reader.onloadend = () => {
          setCommentInput({
            ...commentInput,
            image: target,
            previewImage: String(reader?.result),
          });
        };
      } else if (type == 'pdf') {
        reader.onloadend = () => {
          setCommentInput({
            ...commentInput,
            pdf: target,
            previewPdf: String(reader?.result),
          });
        };
      }

      e.target.value = '';
    }
  };

  useEffect(() => {
    loadDiscussion();
    loadStatus();
  }, [editStatus]);

  return (
    <>
      {LoadingDiscussion ? (
        <>
          <Container>
            <WrapperHeaderInfos>
              {/* Header */}
              <HeaderContainer>
                <Title>{discussion?.title}</Title>
                {ownerId === user.id && (
                  <>
                    <CaseMenu
                      openModal={openOrCloseModal}
                      editStatus={() => setEditStatus(true)}
                      isOwner={ownerId === user.id}
                      discussionId={discussionId}
                    />
                  </>
                )}
              </HeaderContainer>

              {/* Details */}
              <CaseDetailsContainer>
                {countDeadline(discussion?.deadline) < 1 ? (
                  <>
                    <CaseDeadline>Prazo encerrado</CaseDeadline>
                  </>
                ) : (
                  <>
                    <CaseDeadline>
                      {countDeadline(discussion?.deadline)} dia(s)
                    </CaseDeadline>
                    <DeadlineText> restantes </DeadlineText>
                  </>
                )}
                <StatusText> Status: </StatusText>
                {editStatus ? (
                  <SelectStatus onChange={handleChange}>
                    <option
                      key={discussion.status.id}
                      value={discussion.status.id}
                    >{`${discussion.status.emoji} ${discussion.status.name}`}</option>
                    {allStatus.map(
                      (st: { id: string; emoji: string; name: string }) =>
                        discussion.status.id !== st.id && (
                          <option
                            key={st.id}
                            value={st.id}
                          >{`${st.emoji} ${st.name}`}</option>
                        ),
                    )}
                  </SelectStatus>
                ) : (
                  <CaseStatus>
                    {' '}
                    {`${discussion?.status?.emoji} ${discussion?.status?.name}`}{' '}
                  </CaseStatus>
                )}
              </CaseDetailsContainer>
              <CaseDetailsContainer>
                <CreatedByText>Criado por </CreatedByText>
                <Creator>
                  {' '}
                  {discussion?.discussion_users?.map(
                    (discussionUser: {
                      isOwner: boolean;
                      user: { name: string; surname: string };
                    }) => {
                      if (discussionUser.isOwner == true)
                        return `${discussionUser.user.name} ${discussionUser.user.surname}`;
                    },
                  )}{' '}
                </Creator>
              </CaseDetailsContainer>

              {/* Doctors */}
              {!discussion.isPublic ? (
                <>
                  <DoctorsTitle> Participantes </DoctorsTitle>
                  <DoctorsContainer>
                    {discussionUsers?.map(({ user }: any, index: any) => (
                      <Doctor
                        key={index}
                        zIndex={index}
                        photo={user.photo_address}
                      />
                    ))}
                    {discussion?.discussion_users.length > 9 ? (
                      <MoreDoctors>
                        <MoreDoctorsText>
                          +{discussion.discussion_users.length - 9}
                        </MoreDoctorsText>
                      </MoreDoctors>
                    ) : (
                      <></>
                    )}
                  </DoctorsContainer>
                </>
              ) : (
                <DoctorsTitle> Discussão pública </DoctorsTitle>
              )}
            </WrapperHeaderInfos>

            {/* Body */}
            <HomeDivisor width={100} />
            <CaseBody>{parse(`${discussion?.description}`)}</CaseBody>
          </Container>

          {/* Comments */}
          <CommentsContainer>
            <CommentsTitle>Comentários</CommentsTitle>
            <CommentsWrapper>
              {discussion?.discussion_comments?.map(
                (comment: any, index: any) => (
                  <Comment
                    key={index}
                    body={comment?.content}
                    image={comment?.image_address}
                    photo={comment?.user?.photo_address}
                    time={comment && hoursAndMinutes(comment?.created_at)}
                    date={comment && dayAndMonth(comment?.created_at, 'short')}
                  />
                ),
              )}
            </CommentsWrapper>
            <AddComment
              name="usercomment"
              value={commentInput.content}
              placeholder="Adicionar comentário"
              onChange={e =>
                setCommentInput({
                  ...commentInput,
                  content: e.target.value,
                })
              }
            />
            <CommentsBottomContainer>
              <CreateCommentFileContainer
                onClick={() =>
                  setCommentInput({
                    ...commentInput,
                    image: '',
                    previewImage: '',
                  })
                }
              >
                {commentInput.image != '' && (
                  <>
                    <CreateCommentFilePreview src={commentInput.previewImage} />
                    <OverlayCommentFilePreview>
                      <span>X</span>
                    </OverlayCommentFilePreview>
                  </>
                )}
              </CreateCommentFileContainer>
              <CreateCommentFileContainer
                onClick={() =>
                  setCommentInput({
                    ...commentInput,
                    pdf: '',
                    previewPdf: '',
                  })
                }
              >
                {commentInput.pdf != '' && (
                  <>
                    <Pdf style={{ maxHeight: '52px', maxWidth: '66px' }} />
                    <OverlayCommentFilePreview>
                      <span>X</span>
                    </OverlayCommentFilePreview>
                  </>
                )}
              </CreateCommentFileContainer>
              <ButtonsContainer>
                {!commentInput.image && (
                  <SelectFileButtonContainer>
                    <SelectFileButton>
                      <SelectFileIconContainer>
                        <Gallery />
                        <input
                          type="file"
                          accept="application/pdf"
                          onChange={e => handleFileComment(e, 'pdf')}
                        />
                      </SelectFileIconContainer>
                      {commentInput.previewPdf
                        ? 'Mudar Arquivo'
                        : 'Selecionar Arquivo'}
                    </SelectFileButton>
                  </SelectFileButtonContainer>
                )}
                {!commentInput.pdf && (
                  <SelectFileButtonContainer>
                    <SelectFileButton>
                      <SelectFileIconContainer>
                        <Gallery />
                        <input
                          type="file"
                          accept="image/jpeg, image/jpg, image/pjpeg, image/png"
                          onChange={e => handleFileComment(e, 'image')}
                        />
                      </SelectFileIconContainer>
                      {commentInput.previewImage
                        ? 'Mudar Imagem'
                        : 'Selecionar imagem'}
                    </SelectFileButton>
                  </SelectFileButtonContainer>
                )}
                <SendButton
                  onClick={() => {
                    createComment();
                  }}
                >
                  {' '}
                  Enviar{' '}
                </SendButton>
              </ButtonsContainer>
            </CommentsBottomContainer>
          </CommentsContainer>
        </>
      ) : (
        <LoaderContainer>
          <ThreeDots color={theme.color.mainBrown} height={100} width={100} />
        </LoaderContainer>
      )}

      {/* Modal */}
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <DeleteContainer>
          <DeleteWarningTitle>
            Você tem certeza que deseja deletar essa discussão?
          </DeleteWarningTitle>
          <DeleteWarningText>
            {' '}
            Esta é uma ação irreversível. Tem certeza que deseja continuar?{' '}
          </DeleteWarningText>
          <ModalButtonsContainer>
            <CancelButton onClick={openOrCloseModal}> Cancelar </CancelButton>
            <DeleteButton
              onClick={() => {
                deleteDiscussion();
                openOrCloseModal();
              }}
            >
              {' '}
              Deletar{' '}
            </DeleteButton>
          </ModalButtonsContainer>
        </DeleteContainer>
      </Modal>
    </>
  );
};

export default SpecificCase;
