import styled from 'styled-components';

export const Patient = styled.div`
  align-self: flex-start;
  background-color: ${props => props.theme.color.white};
  width: 100%;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }

  transition: background-color 0.1s ease-in;
`;

export const PatientHover = styled.div`
  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }

  transition: background-color 0.1s ease-in;
`;

export const TextsContainer = styled.div`
  display: flex;
  margin-top: 0.7rem;
  padding-bottom: 1.5rem;
`;

export const PatientTitle = styled.p`
  padding-top: 1.5rem;
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.black};
`;

export const PatientSendBy = styled.p`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
`;

export const SendByText = styled.p`
  margin-right: 0.5rem;
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
`;
