import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import NavItem from '../NavItem';
import NotificationBox from '../NotificationBox';

import { Logo } from '../../assets/icons';

import {
  NavContainer,
  MainContentContainer,
  ListOfItems,
  ListOfIcons,
  IconContainer,
  ProfileImageContainer,
  ProfileImage,
} from './Navbar.elements';
import theme from '../../assets/styles/theme';

const Navbar: React.FC = () => {
  const { pathname } = useLocation();
  const { user } = useAuth();

  return (
    <NavContainer>
      <MainContentContainer>
        <Logo />

        <ListOfItems>
          {/* Navbar options */}
          <NavItem
            text={'Página inicial'}
            redirectTo={'/'}
            isActive={pathname === '/'}
          />
          <NavItem
            text={'discussão de casos'}
            redirectTo={'/discussao-casos'}
            isActive={pathname === '/discussao-casos'}
          />
        </ListOfItems>

        <ListOfIcons>
          {/* Notifications icon */}
          <IconContainer id={'notification'}>
            <NotificationBox />
          </IconContainer>

          {/* Profile picture */}
          <ProfileImageContainer>
            <Link to="/perfil">
              <ProfileImage src={user.photo_address || theme.defaultImage} />
            </Link>
          </ProfileImageContainer>
        </ListOfIcons>
      </MainContentContainer>
    </NavContainer>
  );
};

export default Navbar;
