import styled from 'styled-components';
import {
  ModalButton,
  ModalContent,
  ModalTitle,
} from '../GlobalModalContentStyle';

export const PrivacyTermsModalContent = styled(ModalContent)`
  width: 80rem;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-row-gap: 2rem;
  grid-template-areas:
    'privacyTermsTitle'
    'privacyTermsDescription'
    'privacyTermsButtonsContainer';
`;

export const PrivacyTermsTitle = styled(ModalTitle)`
  grid-area: privacyTermsTitle;
`;

export const PrivacyTermsDescription = styled.p`
  max-height: 40rem;
  overflow-y: auto;
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.blue};
  white-space: pre-line;
  line-height: 2rem;
  grid-area: privacyTermsDescription;
  padding: 1rem 1rem;
  border-top: 1px solid #d1d3d4;
  border-bottom: 1px solid #d1d3d4;
`;

export const PrivacyTermsButtonsContainer = styled.div`
  grid-area: privacyTermsButtonsContainer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PrivacyTermsButton = styled(ModalButton)``;
