import { createGlobalStyle } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

export default createGlobalStyle`

  :root {
    font-size: 62.5%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html,
  body,
  #root {
    height: 100vh;
    position: relative;
    z-index: -10000;
  }

  body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: ${props => props.theme.color.backgroundGray};
    user-select: none;
    overflow-x: hidden !important;
  }

  .react-datepicker {
    border: 0.5px solid #c9c9c9;

    .react-datepicker__month-container {

      font: ${props => props.theme.font.smallText};
      color: ${props => props.theme.color.black};
      text-transform: capitalize;
      min-width: 20rem;


      .react-datepicker__header  {
        width: 100%;
        padding: 0.5rem 0.5rem;

        background-color: ${props => props.theme.color.whiteHover};
        .react-datepicker__current-month {
          font: ${props => props.theme.font.text};
          margin-top: 0.5rem;
        }
        .react-datepicker__day-names {
          display: flex;
          margin: 0.5rem 0;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .react-datepicker__day-name {
            margin: 0;
          }
        }
      }

      .react-datepicker__month {
        padding: 0.5rem 0.5rem;

        .react-datepicker__week {
          display: flex;
          justify-content: space-between;
        }

        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
          background-color: ${props => props.theme.color.mainBrown};
        }
        .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
          background-color: ${props => props.theme.color.secondaryBrown};
        }
      }

    }
  }


  button {
    cursor: pointer;
  }
`;
