import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: 'searchDiscussionContainer selectContainer';
`;

export const SelectsContainer = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'orderDiscussion statusDiscussion';
  grid-area: selectContainer;
`;

export const SearchDiscussionContainer = styled.div`
  display: flex;
  align-items: center;
  grid-area: searchDiscussionContainer;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 1rem;

  svg {
    height: 2.4rem;
    cursor: pointer;

    path {
      fill: ${props => props.theme.color.blue};
      fill-opacity: 1;
    }
  }
`;

export const SearchDiscussion = styled.input`
  width: 100%;
  padding: 1rem 1.5rem 1rem 4rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`;

export const OrderDiscussion = styled.select`
  grid-area: orderDiscussion;

  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`;

export const StatusDiscussion = styled.select`
  grid-area: statusDiscussion;

  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`;
