import React from 'react';
import { Link } from 'react-router-dom';

import { NavItemContainer, NavText } from './NavItem.elements';

type NavItemProps = {
  text: string;
  redirectTo: string;
  isActive: boolean;
};

const NavItem: React.FC<NavItemProps> = ({
  text,
  redirectTo,
  isActive,
}: NavItemProps) => {
  return (
    <Link to={redirectTo} style={{ textDecoration: 'none' }}>
      <NavItemContainer>
        <NavText selected={isActive}>{text}</NavText>
      </NavItemContainer>
    </Link>
  );
};

export default NavItem;
