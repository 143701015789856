import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import {
  Container,
  CommentBody,
  InsideContainer,
  Photo,
  DetailsContainer,
  Time,
  Date,
  CommentImage,
  CommentImageModal,
} from './Comment.elements';
import { Pdf } from '../../assets/icons';

type CommentProps = {
  body: string;
  image?: string;
  photo: string;
  time: string;
  date: string;
};

const Comment: React.FC<CommentProps> = ({
  body,
  image,
  photo,
  time,
  date,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [commentImageModal, setCommentImageModal] = useState('');

  const openOrCloseModal = () => {
    setShowModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  useEffect(() => {
    if (image) {
      console.log(image.slice(-3));
    }
  }, []);
  return (
    <>
      <Container>
        <Photo photo={photo} />
        <InsideContainer>
          <CommentBody>{body}</CommentBody>
          {image &&
            (image.slice(-3) === 'pdf' ? (
              <Pdf
                onClick={() => window.open(image)}
                style={{ width: '50px', height: '50px', cursor: 'pointer' }}
              ></Pdf>
            ) : (
              <CommentImage
                src={image}
                onClick={() => {
                  openOrCloseModal();
                  setCommentImageModal(image);
                }}
              />
            ))}

          <DetailsContainer>
            <Time>{time}</Time>
            <Date>{date}</Date>
          </DetailsContainer>
        </InsideContainer>
      </Container>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <CommentImageModal src={commentImageModal} />
      </Modal>
    </>
  );
};

export default Comment;
