/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Container, OptionTitle, IconContainer } from './MenuOptions.elements';
import { useHistory } from 'react-router-dom';

interface MenuOptionsProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  redirectTo?: string;
  icon: any;
}

const MenuOptions: React.FC<MenuOptionsProps> = ({
  icon: Component,
  title,
  redirectTo,
}) => {
  const history = useHistory();

  const redirect = () => {
    redirectTo && history.push(redirectTo);
  };
  return (
    <>
      <Container onClick={redirect}>
        <IconContainer>
          <Component />
        </IconContainer>
        <OptionTitle>{title}</OptionTitle>
      </Container>
    </>
  );
};

export default MenuOptions;
