import React from 'react';
import { DivisorLine } from './Divisor.elements';

type DivisorProps = {
  width?: number;
};

const Divisor: React.FC<DivisorProps> = ({ width }: DivisorProps) => {
  return (
    <>
      <DivisorLine width={width} />
    </>
  );
};

export default Divisor;
