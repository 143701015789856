import { HTMLAttributes } from 'react';
import styled from 'styled-components';

import {
  ModalButton,
  ModalContent,
  ModalTitle,
} from '../GlobalModalContentStyle';

interface FontSizeOptionProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLHeadingElement>> {
  fontSize?: number;
}

export const ChangeFontSizeModalContent = styled(ModalContent)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-row-gap: 4rem;
  grid-template-areas:
    'changeFontSizeTitle'
    'changeFontSizeContainer'
    'changeFontSizeButtonsContainer';
`;

export const ChangeFontSizeTitle = styled(ModalTitle)`
  grid-area: changeFontSizeTitle;
  font: ${props => props.theme.font.mediumText};
`;

export const ChangeFontSizeContainer = styled.div`
  grid-area: changeFontSizeContainer;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto auto;
  grid-column-gap: 4rem;
  justify-content: center;
  align-items: center;
`;

export const ChangeFontSizeOption = styled.h1<FontSizeOptionProps>`
  color: ${props =>
    props.fontSize === Number(props.id)
      ? props.theme.color.mainBrown
      : '#c9c9c9'};
  font-size: ${props => 1.6 * Number(props.id)}rem;
  cursor: pointer;
`;

export const ChangeFontSizeButtonsContainer = styled.div`
  grid-area: changeFontSizeButtonsContainer;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  grid-column-gap: 2rem;
`;

export const ChangeFontSizeButton = styled(ModalButton)``;
